<template>
  <div>
    <button type="button"
            @click="generatePDF"
            class="btn btn-primary"
            style="background-color:#FAE565; color:#000; border:1px solid #FAE565;"
    >
      <i class="bi bi-printer"></i> Print Design Details
    </button>
  </div>
</template>

<script>
import Fraction from "fraction.js";
import jsPDF from 'jspdf';

export default {
  props: ['selectedFilters', 'builtFrame', 'printNotes'],
  methods: {
    createHeaders(keys) {
      var result = [];
      for (var i = 0; i < keys.length; i += 1) {
        result.push({
          id: keys[i],
          name: keys[i],
          prompt: keys[i],
          width: 65,
          align: "center",
          padding: 0
        });
      }
      return result;
    },
    widthFrac(decWidth) {
      let f = new Fraction(decWidth);
      let res = f.toFraction(true);
      let resx = res.split(" ");

      let den = "";
      if (resx[1] !== undefined) {
        den = resx[1];
      }

      //return (isNaN(resx[0]) ? 0 : resx[0]) + ' ' + (isNaN(den) ? 0 : den);
      //return (isNaN(resx[0]) ? 0 : resx[0]) + ' ' + den;
      return resx[0] + ' ' + den;
    },
    generatePDF() {
      let self = this

      let images = document.getElementsByClassName('builtFrameImg');
      let imageWidth = 0;
      // Loop through each image and get its height and width
      for (let i = 0; i < images.length; i++) {
        let image = images[i];
        //var height = image.naturalHeight;
        imageWidth = image.naturalWidth;
      }

      const doc = new jsPDF();
      let text = 'Frame Information: Width ' + this.widthFrac(this.selectedFilters.fWidth) + ' X Height ' + this.widthFrac(this.selectedFilters.fHeight);
      let textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
      // Calculate the x coordinate for centering the text
      let centerX = (doc.internal.pageSize.width - textWidth) / 2;
      console.log('xxx', doc.internal.pageSize.width, textWidth)
      // Place the text at the calculated position
      doc.text(text, centerX, 10);

      doc.setFontSize(12)

      let docWidth = (doc.internal.pageSize.width);

      // Get the width of the image
      //var imageWidth = // Width of your image, you need to specify it here;
      let imageWidthS = this.selectedFilters.fWidth * doc.internal.scaleFactor
      console.log('w', this.selectedFilters.fWidth, parseFloat(imageWidthS)+' > '+parseFloat(docWidth))
      if(parseFloat(imageWidthS) > parseFloat(docWidth)){
        imageWidth = this.selectedFilters.fWidth * doc.internal.scaleFactor / 2
      }else{
        imageWidth = (this.selectedFilters.fWidth) * doc.internal.scaleFactor
      }

      // Calculate the x-coordinate to center the image
      var x = (docWidth - imageWidth) / 2;

      // Add the image to the document at the calculated x-coordinate and y-coordinate 15
      doc.addImage(this.builtFrame, "JPEG", x, 15);


      if(this.printNotes !== undefined){
        let text = this.printNotes;
        let fontSize = 12; // Adjust the font size as needed
        let lineHeight = .5 * fontSize; // Adjust line height as needed
        let textWidth = doc.getStringUnitWidth(text) * fontSize / doc.internal.scaleFactor;

        // Calculate the x coordinate for centering the text
        let centerX = (doc.internal.pageSize.width - textWidth) / 2;
        console.log('cc', centerX)
        // Calculate the y coordinate for centering the text vertically
        //let verticalOffset = (doc.internal.pageSize.height - lineHeight) / 2;
        let verticalOffset = 155

        // Split text into lines to wrap it
        let textLines = doc.splitTextToSize(text, doc.internal.pageSize.width - 20); // Adjust the width as needed

        // Place each line at the calculated position
        textLines.forEach((line, index) => {
          let y = verticalOffset + (index * lineHeight);
          doc.text(line, 10, y);
        });
      }


      if (this.selectedFilters.moulding.Code !== undefined) {

        let mdata = [{name: 'Moulding', value: self.selectedFilters.moulding.Code},
                    {name: 'Collection', value: self.selectedFilters.moulding.collection_name},
                    {name: 'Finish', value: self.selectedFilters.moulding.finish_name},
                    {name: 'Width', value: this.widthFrac(self.selectedFilters.moulding.Width)},
                    {name: 'Rabbet', value: self.selectedFilters.moulding.rabbet},
                    {name: 'Style', value: self.selectedFilters.moulding.style_name},
                  ];

        let mheaders = this.createHeaders([
          "name",
          "value",
        ]);

        doc.table(5, 175, mdata, mheaders, {autoSize: true, printHeaders: false, fontSize: 8});

      }
      if (this.selectedFilters.mat.Code !== undefined) {

        let matOpenWidth = this.widthFrac(
            parseFloat(self.selectedFilters.matOpenWidth || 0) +
            parseFloat(self.selectedFilters.matOpenWidthFrac || 0)
        );

        let matOpenHeight = this.widthFrac(
            parseFloat(self.selectedFilters.matOpenHeight || 0) +
            parseFloat(self.selectedFilters.matOpenHeightFrac || 0)
        );

        let matTop = this.widthFrac(
            parseFloat(self.selectedFilters.matTop || 0) +
            parseFloat(self.selectedFilters.matTopFrac || 0)
        );

        let matBottom = this.widthFrac(
            parseFloat(self.selectedFilters.matBottom || 0) +
            parseFloat(self.selectedFilters.matBottomFrac || 0)
        );

        let matLeft = this.widthFrac(
            parseFloat(self.selectedFilters.matLeft || 0) +
            parseFloat(self.selectedFilters.matLeftFrac || 0)
        );

        let matRight = this.widthFrac(
            parseFloat(self.selectedFilters.matRight || 0) +
            parseFloat(self.selectedFilters.matRightFrac || 0)
        );

        let mDim = 'Top: '+matTop+' Bottom: '+matBottom+"\nLeft: "+matLeft+' Right: '+matRight
        let matdata = [{name: 'Mat', value: self.selectedFilters.mat.Code},
          {name: 'Mat Description', value: self.selectedFilters.mat.Description},
          {name: 'Mat Size', value: this.widthFrac(self.selectedFilters.mat.Width)},
          {name: 'Mat Borders', value: mDim},
          {name: 'Mat Opening', value: matOpenWidth+(matOpenWidth === '' && matOpenHeight === '' ? ' ' : ' X ')+matOpenHeight}
        ];

        let matheaders = this.createHeaders([
          "name",
          "value",
        ]);

        doc.table(90, 175, matdata, matheaders, {autoSize: false, printHeaders: false, fontSize: 8 });


      }
      if (this.selectedFilters.glass.Code !== undefined) {
        let gdata = [{name: 'Glazing', value: self.selectedFilters.glass.Code},
          {name: 'Description', value: self.selectedFilters.glass.Description},
        ];

        let gheaders = this.createHeaders([
          "name",
          "value",
        ]);

        doc.table(5, 245, gdata, gheaders, {autoSize: true, printHeaders: false, fontSize: 8 });
      }

      doc.save('example.pdf');

    }
  }
}
</script>
