<template>
  <div class="container">
    <div class="row mt-5 mb-5">
      <div class="col-2"></div>
      <div class="col-8" >

        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Who We Are</h5>
            <p class="card-text" v-html="who"></p>
          </div>
        </div>

      </div>
      <div class="col-2"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "whoWeAre",
  data() {
    return {
      loader: "dots",
      who: ''
    };
  },
  mounted() {
    this.$store.dispatch('product/settingsData').then(rez => {
      console.log(rez)
      this.who = rez.find(obj => obj.name === "who_we_are").data;
    })
  }
}
</script>

<style scoped>
.card:hover {
  overflow: hidden;
  transform: none;
}
</style>