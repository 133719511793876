<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-3">
        <h4 class="mt-4 mb-4 ml-3 fw-bold d-none d-lg-block">Filters</h4>
        <div
            class="offcanvas-lg offcanvas-start"
            tabindex="-1"
            id="offcanvasResponsive"
            aria-labelledby="offcanvasResponsiveLabel"
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-titl fw-bold" id="offcanvasResponsiveLabel">
              Filters
            </h5>
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="offcanvas"
                data-bs-target="#offcanvasResponsive"
                aria-label="Close"
            ></button>
          </div>

          <VueMultiselect
              v-model="selectedSearch"
              :select-label="''"
              :options="options"
              :close-on-select="true"
              :clear-on-select="true"
              :hide-selected="true"
              :internal-search="false"
              :allow-empty="true"
              :block-keys="['Tab']"
              open-direction="bottom"
              placeholder="Search by name or Item #"
              @search-change="asyncSearch"
              label="Code"
              track-by="Code"
              @keydown.enter="preventEnter"
              :loading="isLoading"
          >
            <template v-slot:option="{ option }">
              <b>{{ option.Code }}</b>
              <br/>
              <span>{{ option.Description }}</span>
            </template>
            <template #noResult>
              No products found
            </template>
          </VueMultiselect>

          <div class="row adtlSF">
            <div class="col-6">
              <div
                  id="searchLeft"
                  class="accordion-collapse collapse show"
                  aria-labelledby="flush-headingEight"
                  data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  <div
                      class="form-check"
                      v-for="type in (availableFilters.type ? availableFilters.type.slice(0, 3) : [])"
                      :key="type.value"
                  >
                    <input
                        class="form-check-input"
                        type="radio"
                        name="flt_leftsearch"
                        v-model="adtlSFType"
                        :value="[type.value]"
                        :id="'defaultCheckSF_'+type.value"
                    />
                    <label
                        class="form-check-label"
                        :for="'defaultCheckSF_'+type.value"
                    >
                      {{ type.name }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div
                  id="searchRight"
                  class="accordion-collapse collapse show"
                  aria-labelledby="flush-headingEight"
                  data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  <div
                      class="form-check"
                      v-for="type in (availableFilters.type ? availableFilters.type.slice(3, 6) : [])"
                      :key="type.value"
                  >
                    <input
                        class="form-check-input"
                        type="radio"
                        name="flt_rightsearch"
                        v-model="adtlSFType"
                        :value="[type.value]"
                        :id="'defaultCheckSF_'+type.value"
                    />
                    <label
                        class="form-check-label"
                        :for="'defaultCheckSF_'+type.value"
                    >
                      {{ type.name }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row adtlSFLbl">
            <div class="col-8">
              <div>Additional Filters</div>
            </div>
            <div class="col-4 text-end">
              <div>
                <a href="#" @click.prevent="clearSF">Clear</a>
              </div>
            </div>
          </div>

          <div class="offcanvas-body">
            <div
                class="accordion accordion-flush products-filter"
                id="accordionFlushExample"
            >
              <div class="accordion-item" v-if="isLoggedin">
                <h2 class="accordion-header" id="flush-headingFP">
                  <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFP"
                      aria-expanded="false"
                      aria-controls="flush-collapseFP"
                      @click="getFrequentlyPurchased()"
                  >
                    Frequently Purchased
                  </button>
                </h2>
                <div
                    id="flush-collapseFP"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingFP"
                    data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body" style="padding:0;">

                  </div>
                </div>
              </div>

              <div class="accordion-item" v-show="showCollectionFilter">
                <h2 class="accordion-header" id="flush-headingThree">
                  <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                  >
                    Collection
                  </button>
                </h2>
                <div
                    id="flush-collapseThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <div
                        class="form-check"
                        v-for="filtersData in availableFilters.collection"
                        :key="filtersData.id"
                    >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          name="flt_width"
                          v-model="filters.collection"
                          :value="filtersData.id"
                          :id="'defaultColl_'+filtersData.id"
                      />
                      <label
                          class="form-check-label"
                          :for="'defaultColl_'+filtersData.id"
                      >
                        {{ filtersData.name + ' (' + (availableFilters.collectionCount[filtersData.id] || 0) + ')' }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item" v-show="!filters.isFC && filters.isFC !== undefined">
                <h2 class="accordion-header" id="flush-headingEight">
                  <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseEight"
                      aria-expanded="false"
                      aria-controls="flush-collapseEight"
                  >
                    Products
                  </button>
                </h2>
                <div
                    id="flush-collapseEight"
                    class="accordion-collapse collapse show"
                    aria-labelledby="flush-headingEight"
                    data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <div
                        class="form-check"
                        v-for="type in availableFilters.type"
                        :key="type.value"
                    >
                      <input
                          class="form-check-input"
                          type="radio"
                          name="flt_width"
                          v-model="filters.type"
                          :value="[type.value]"
                          :id="'defaultCheck_'+type.value"
                      />
                      <label
                          class="form-check-label"
                          :for="'defaultCheck_'+type.value"
                      >
                        {{ type.name }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>


              <div class="accordion-item" v-show="showWidthFilter">
                <h2 class="accordion-header" id="flush-headingOne">
                  <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                  >
                    Width
                  </button>
                </h2>
                <div
                    id="flush-collapseOne"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <width-select-box @selected="handleSetWidth"></width-select-box>
                  </div>
                </div>
              </div>
              <div class="accordion-item" v-show="showHeightFilter">
                <h2 class="accordion-header" id="flush-headingNine">
                  <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseNine"
                      aria-expanded="false"
                      aria-controls="flush-collapseNine"
                  >
                    Height
                  </button>
                </h2>
                <div
                    id="flush-collapseNine"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingNine"
                    data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <width-select-box @selected="handleSetHeight"></width-select-box>
                  </div>
                </div>
              </div>
              <div class="accordion-item" v-show="showRabbetFilter">
                <h2 class="accordion-header" id="flush-headingTwo">
                  <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                  >
                    Rabbet Depth
                  </button>
                </h2>
                <div
                    id="flush-collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <width-select-box @selected="handleSetRabbet"></width-select-box>
                  </div>
                </div>
              </div>

              <div class="accordion-item" v-show="showFinishFilter">
                <h2 class="accordion-header" id="flush-headingFive">
                  <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFive"
                      aria-expanded="false"
                      aria-controls="flush-collapseFive"
                  >
                    Finish Type
                  </button>
                </h2>
                <div
                    id="flush-collapseFive"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingFive"
                    data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <div
                        class="form-check"
                        v-for="finish in availableFilters.finish"
                        :key="finish.value"
                    >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          name="flt_width"
                          v-model="filters.finish"
                          :value="finish.value"
                          :id="'defaultFinish_'+finish.value"
                      />
                      <label
                          class="form-check-label"
                          :for="'defaultFinish_'+finish.value"
                      >
                        {{ finish.name + ' (' + finish.count + ')' }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item" v-show="showStyleFilter">
                <h2 class="accordion-header" id="flush-headingSeven">
                  <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseSeven"
                      aria-expanded="false"
                      aria-controls="flush-collapseSeven"
                  >
                    Style
                  </button>
                </h2>
                <div
                    id="flush-collapseSeven"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingSeven"
                    data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <div
                        class="form-check"
                        v-for="style in availableFilters.style"
                        :key="style.value"
                    >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          name="flt_width"
                          v-model="filters.style"
                          :value="style.value"
                          :id="'defaultStyle_'+style.value"
                      />
                      <label
                          class="form-check-label"
                          :for="'defaultStyle_'+style.value"
                      >
                        {{ style.name + ' (' + style.count + ')' }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="accordion-item" v-show="showColorFamilyFilter">
                <h2 class="accordion-header" id="flush-headingTen">
                  <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTen"
                      aria-expanded="false"
                      aria-controls="flush-collapseTen"
                  >
                    Color Family
                  </button>
                </h2>
                <div
                    id="flush-collapseTen"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingTen"
                    data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <div
                        class="form-check"
                        v-for="filtersData in availableFilters.color_family"
                        :key="filtersData.id"
                    >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          name="flt_width"
                          v-model="filters.color_family"
                          :value="filtersData.id"
                          :id="'defaultColor_'+filtersData.id"
                      />
                      <label
                          class="form-check-label"
                          :for="'defaultColor_'+filtersData.id"
                      >
                        {{ filtersData.name + ' (' + (availableFilters.colorFamilyCount[filtersData.id] || 0) + ')' }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="accordion-item" v-show="showProfileTypeFilter">
                <h2 class="accordion-header" id="flush-headingEleven">
                  <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseEleven"
                      aria-expanded="false"
                      aria-controls="flush-collapseEleven"
                  >
                    Profile Type
                  </button>
                </h2>
                <div
                    id="flush-collapseEleven"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingEleven"
                    data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <div
                        class="form-check"
                        v-for="filtersData in availableFilters.profile_type"
                        :key="filtersData.id"
                    >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          name="flt_width"
                          v-model="filters.profile_type"
                          :value="filtersData.id"
                          :id="'defaultProfile_'+filtersData.id"
                      />
                      <label
                          class="form-check-label"
                          :for="'defaultProfile_'+filtersData.id"
                      >
                        {{ filtersData.name + ' (' + (availableFilters.profileTypeCount[filtersData.id] || 0) + ')' }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-9 mt-4 mb-4">
        <button
            class="btn btn-primary d-lg-none"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasResponsive"
            aria-controls="offcanvasResponsive"
        >
          View Filters
        </button>
        <div class="row">
          <div v-for="(value, key, index) in nonEmptyFilters" :key="index">
            <div class="col" v-if="value.length > 0 && isArray(value)">
              <span
                  class=" badge rounded-pill text-bg-light mb-3 p-2 text-uppercase shadow-sm border-secondary"
                  v-for="selFilters in value"
                  :key="selFilters"
              >
                {{ (key === 'type' ? 'Products' : key) + ": " + filterByValue(key, selFilters) }}
                <button
                    type="button"
                    class="btn-close"
                    aria-label="Close"
                    v-if="!isFrameBuilder || (key === 'TYPE' && isFrameBuilder)"
                    v-on:click.prevent.stop="removeFilterByValue(key, selFilters)"
                ></button>
              </span>

            </div>
            <div class="col" v-if="value.length > 0 && !isArray(value) && value !== undefined && value !== ''">
              <span
                  class="badge rounded-pill text-bg-light mb-3 p-2 text-uppercase shadow-sm border-secondary"
                  :key="value"
              >
                {{ (key === 'type' ? 'Products' : key) + ": " + value }}
                <button
                    type="button"
                    class="btn-close"
                    aria-label="Close"
                    v-on:click.prevent.stop="removeFilterByValue(key, value)"
                ></button>
              </span>
            </div>
          </div>
        </div>
        <!--
        <RecentPurchase v-if="this.filters.Code === '' && !isFrameBuilder"></RecentPurchase>
        -->
        <div class="row">
          <div class="col text-end">
            Total Results {{ totalResults.toLocaleString() }}
          </div>
        </div>
        <div class="row row-cols-1 row-cols-xl-3 g-4 product-search card-group">
          <div class="col col-lg-4 col-xl-3" v-for="item in productData" :key="item">
            <div class="card card-products shadow-md">
              <div class="card-img">
                <img
                    :src="imageUrl(item)"
                    @error="imageUrlAlt"
                    class="card-img-top img-fluid"
                    alt="..."
                />
              </div>

              <div class="card-body">
                <div class="row">
                  <div class="col text-start">
                    <span class="text-uppercase d-block overflow-x-hidden text-nowrap text-truncate codeFS">#{{
                        item.Code
                      }}</span>
                  </div>
                  <div class="col text-end">
                    <span class="d-block fw-bold" v-if="item.FrameType === 'MOULDING'"
                          v-html="widthFrac(item.Width)"></span>
                  </div>
                </div>
                <div class="row">
                  <div class="col text-start">
                    <span class="d-block fs-5 text subdued overflow-x-hidden text-nowrap text-truncate dispH"
                          :title="item.display_name">
                      {{ item.display_name }}
                    </span>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col text-start">
                    <a href="#" class="btn btn-outline-dark rounded-0 d-block mb-2" @click.prevent="shopDetail(item)">Details</a>
                    <a href="#" class="btn btn-dark rounded-0 d-block"
                       v-if="isLoggedin && !isFrameBuilder"
                       @click.prevent.stop="addToCart(item)">+ Add to Cart</a>
                    <a
                        href="#"
                        class="btn btn-dark rounded-0 d-block"
                        v-if="isFrameBuilder"
                        v-on:click.prevent.stop="frameMouldingBuilder(item)"
                    >Select</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-show="hasMore">
          <div class="col-12 text-center mt-3">
            <button class="btn btn-primary"
                    style="padding-left:50px; padding-right:50px;"
                    @click="loadNextPage()"
            >MORE
            </button>
          </div>
        </div>

        <!--
        <RecentPurchase v-if="this.filters.Code !== '' && !isFrameBuilder"></RecentPurchase>
        -->
        <div class="row">
          <div class="col text-center mt-4" v-show="infShowLoader">
            <div class="spinner-grow text-dark" role="status"></div>
            <div class="spinner-grow text-dark" role="status"></div>
            <div class="spinner-grow text-dark" role="status"></div>
            <div class="spinner-grow text-dark" role="status"></div>
            <div class="spinner-grow text-dark" role="status"></div>
          </div>
        </div>

        <RecentPurchase v-if="!isFrameBuilder"></RecentPurchase>

      </div>
    </div>

    <CartDetails ref="cartD" :detailImg="detailImg" @closeModal="closeModal" @returnData="returnData"></CartDetails>

    <ShopDetails :details="details" :detailData="detailData" :detailImg="detailImg"></ShopDetails>

    <back-order @boRespond="boRespond"></back-order>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Fraction from "fraction.js";
import {Modal} from "bootstrap";
import CartDetails from "@/components/Auth/CartDetails.vue";
import ShopDetails from "@/views/shopDetails.vue";
import VueMultiselect from 'vue-multiselect'
import widthSelectBox from "@/components/widthSelectBox.vue";
import BackOrder from "@/components/Admin/BackOrder.vue";
import RecentPurchase from "@/components/Admin/RecentPurchase.vue";
import FrequentlyPurchased from "@/components/Admin/FrequentlyPurchased.vue";

export default {
  name: "shopView",
  props: ["Code", "searchOptions", "Open"],
  data() {
    return {
      hasMore: true,
      thisSaveItem: {},
      isFrameBuilder: false,
      myModal: null,
      backorderModal: null,
      detailModal: null,
      detailCode: "",
      details: {},
      detailData: {},
      detailImg: "",
      perPage: 50,
      page: 1,
      typeChange: false,
      lastType: '',
      codeOnce: false,
      infShowLoader: false,
      totalResults: 0,
      defaultImg: require("@/assets/images/img-default.png"),
      searchCode: "",
      runWatch: true,
      hasOpen: false,
      isLoading: false,
      selectedSearch: "",
      options: [],
      searchTimer: "",
      clearWatch: false,
      hasQuery: false,
      frequently_purchased_active: false,
      adtlSFType: '',
      filters: {
        width: [],
        height: [],
        rabbet: [],
        finish: [],
        style: [],
        collection: [],
        type: [],
        Code: "",
        profile_type: [],
        color_family: [],
        frequently_purchased: [],
        isFC: false
      },
    };
  },
  components: {
    CartDetails,
    ShopDetails,
    VueMultiselect,
    widthSelectBox,
    BackOrder,
    RecentPurchase
  },
  watch: {
    // whenever question changes, this function will run
    'filters.type': {
      handler() {
        if (this.runWatch && this.filters.type.length > 0) {
          let curType = this.filters.type[0]
          this.typeChange = true

          this.runWatch = false
          //let self = this
          this.clearFilters(curType, function () {
            //self.searchProducts();
          })

        } else {
          this.runWatch = true
          this.typeChange = false
        }
      }
    },
    filters: {
      handler() {
        let self = this
        //let typeC = this.typeChange
        setTimeout(function () {
          if (self.runWatch) {
            if (!self.typeChange) {
              self.searchProducts();
            }
          }
        }, 600)
      },
      deep: true,
    },
    selectedSearch: {
      handler(newVal) {
        if (!this.clearWatch) {
          if (newVal.Code != null && newVal.Code != '') {
            this.isLoading = false
            //this.filters.Code = newVal.Code
            let self = this
            this.clearFilters('', function () {
              self.filters.Code = newVal.Code
              //self.searchProducts();
            })

            //window.location.href = '/#/Shop/' + newVal.Code;
          }
          this.clearWatch = true
          this.selectedSearch = ''
        } else {
          this.clearWatch = false
        }
      },
      deep: true,
    },
    $route() {
      // this will be called any time the route changes
      if (this.Code != "") {
        this.filters.type = [];
        this.searchCode = this.Code;
        this.filters.Code = this.Code;
        this.selectedSearch = ''
      }
    },
  },
  computed: {
    nonEmptyFilters() {
      let objFilter = {}
      let obj = this.filters;
      for (let key in obj) {
        if (Array.isArray(obj[key])) {
          if (obj[key].length > 0) {
            objFilter[key] = obj[key]
          }
        } else {
          if (obj[key] !== "") {
            objFilter[key] = obj[key]
          }
        }
      }

      return objFilter
    },

    FrequentlyPurchased() {
      return FrequentlyPurchased
    },
    ...mapGetters({
      productData: "product/getProducts",
      isLoggedin: "userAccess/isLoggedin",
      availableFilters: "product/getFilterData",
    }),
    showProfileTypeFilter() {
      return this.filters.type[0] !== 1 &&
          this.filters.type[0] !== 2 &&
          this.filters.type[0] !== 3 &&
          this.filters.type[0] !== 6 &&
          this.filters.type[0] !== 13 &&
          this.filters.type[0] !== 14
    },
    showColorFamilyFilter() {
      return this.filters.type[0] !== 1 &&
          this.filters.type[0] !== 2 &&
          this.filters.type[0] !== 3 &&
          this.filters.type[0] !== 6 &&
          this.filters.type[0] !== 13 &&
          this.filters.type[0] !== 14
    },
    showHeightFilter() {
      return this.filters.type[0] !== 1 &&
          this.filters.type[0] !== 2 &&
          this.filters.type[0] !== 3 &&
          this.filters.type[0] !== 6 &&
          this.filters.type[0] !== 13 &&
          this.filters.type[0] !== 14
    },
    showWidthFilter() {
      return this.filters.type[0] !== 1 &&
          this.filters.type[0] !== 2 &&
          this.filters.type[0] !== 3 &&
          this.filters.type[0] !== 6 &&
          this.filters.type[0] !== 13 &&
          this.filters.type[0] !== 14
    },
    showRabbetFilter() {
      return this.filters.type[0] !== 1 &&
          this.filters.type[0] !== 2 &&
          this.filters.type[0] !== 3 &&
          this.filters.type[0] !== 6 &&
          this.filters.type[0] !== 13 &&
          this.filters.type[0] !== 14
    },
    showCollectionFilter() {
      return this.filters.type[0] !== 1 &&
          this.filters.type[0] !== 2 &&
          this.filters.type[0] !== 6 &&
          this.filters.type[0] !== 13 &&
          this.filters.type[0] !== 14
    },
    showFinishFilter() {
      return this.filters.type[0] !== 1 &&
          this.filters.type[0] !== 2 &&
          this.filters.type[0] !== 3 &&
          this.filters.type[0] !== 6 &&
          this.filters.type[0] !== 13 &&
          this.filters.type[0] !== 14
    },
    showStyleFilter() {
      return this.filters.type[0] !== 1 &&
          this.filters.type[0] !== 2 &&
          this.filters.type[0] !== 3 &&
          this.filters.type[0] !== 6 &&
          this.filters.type[0] !== 13 &&
          this.filters.type[0] !== 14
    },
  },
  methods: {
    clearSF() {
      this.adtlSFType = ''
    },
    preventEnter(event) {
      if (this.isLoading) {
        event.preventDefault(); // Prevent Enter if loading
      }
    },
    clearFilters(type, callback) {
      let isFC = this.filters.isFC
      let newfilters = {
        width: [],
        height: [],
        rabbet: [],
        finish: [],
        style: [],
        collection: [],
        type: (type !== undefined && type !== '' ? [type] : []),
        Code: "",
        profile_type: [],
        color_family: [],
        frequently_purchased: [],
        isFC: isFC
      }
      this.runWatch = false;
      this.filters = newfilters
      //this.runWatch = true
      if (callback) {
        callback()
      }
    },
    getFrequentlyPurchased() {
      this.frequently_purchased_active = true
      this.clearFilters()
      this.filters.frequently_purchased.push(true)
    },
    handleSetWidth(value) {
      let inputFromValue = parseInt(value.inputFromValue);
      let selectedFromValue = parseFloat(value.selectedFromValue);
      let from = (!isNaN(inputFromValue) ? inputFromValue : 0) + (!isNaN(selectedFromValue) ? selectedFromValue : 0);

      let inputToValue = parseInt(value.inputToValue);
      let selectedToValue = parseFloat(value.selectedToValue);
      let to = (!isNaN(inputToValue) ? inputToValue : 0) + (!isNaN(selectedToValue) ? selectedToValue : 0);

      if (to <= 0 && from > 0) {
        this.filters.width = [from + '|' + from]
      } else if (to > 0 && from > 0) {
        this.filters.width = [from + '|' + to]
      }
    },
    handleSetHeight(value) {
      let inputFromValue = parseInt(value.inputFromValue);
      let selectedFromValue = parseFloat(value.selectedFromValue);
      let from = (!isNaN(inputFromValue) ? inputFromValue : 0) + (!isNaN(selectedFromValue) ? selectedFromValue : 0);

      let inputToValue = parseInt(value.inputToValue);
      let selectedToValue = parseFloat(value.selectedToValue);
      let to = (!isNaN(inputToValue) ? inputToValue : 0) + (!isNaN(selectedToValue) ? selectedToValue : 0);

      if (to <= 0 && from > 0) {
        this.filters.height = [from + '|' + from]
      } else if (to > 0 && from > 0) {
        this.filters.height = [from + '|' + to]
      }
    },
    handleSetRabbet(value) {
      let inputFromValue = parseInt(value.inputFromValue);
      let selectedFromValue = parseFloat(value.selectedFromValue);
      let from = (!isNaN(inputFromValue) ? inputFromValue : 0) + (!isNaN(selectedFromValue) ? selectedFromValue : 0);

      let inputToValue = parseInt(value.inputToValue);
      let selectedToValue = parseFloat(value.selectedToValue);
      let to = (!isNaN(inputToValue) ? inputToValue : 0) + (!isNaN(selectedToValue) ? selectedToValue : 0);

      if (to <= 0 && from > 0) {
        this.filters.rabbet = [from + '|' + from]
      } else if (to > 0 && from > 0) {
        this.filters.rabbet = [from + '|' + to]
      }
    },
    asyncSearch(query) {
      this.isLoading = true
      if (this.searchTimer) {
        clearTimeout(this.searchTimer)
      }
      let self = this
      this.searchTimer = setTimeout(function () {
        //self.isLoading = false
        self.searchNow(query)
      }, 600);

    },
    searchNow(query) {

      if (query == '') {
        this.isLoading = false
        return
      }
      //this.filters.page = 1
      //todo check for isFC add the type
      this.page = 1;
      this.$store
          .dispatch("product/searchTopData", {
            options: {
              frameType: this.adtlSFType ? this.adtlSFType : [],
              TopSearch: query,
              page: 1,
              items_per_page: 10,
              type: (this.filters.isFC ? this.filters.type : this.adtlSFType ? this.adtlSFType : [])
            }
          })
          .then(rez => {
            this.runWatch = false
            //this.filters.type = []
            let self = this
            setTimeout(function () {
              self.runWatch = true
            }, 1000)
            this.options = rez
            this.isLoading = false
          });
    },
    isArray(value) {
      return Array.isArray(value);
    },
    onEnterPressed() {
      this.filters.Code = this.searchCode;
    },
    imageUrl(item) {
      let loc = process.env.VUE_APP_CDN + "/frames/" + item.Code + "/" + item.Code + "-small.jpg";
      if (item.FrameType === 'MATS') {
        loc = process.env.VUE_APP_CDN + "/mat/" + item.ManufCode + "/" + item.ManufCode + "-small.jpg";
      }
      return loc;
    },
    imageUrlAlt(event) {
      event.target.src = this.defaultImg;
    },
    filterByValue(key, val) {
      if (key === 'width' || key === 'rabbet' || key === 'height') {
        let eachVal = val.split('|');
        return this.widthFracDen(eachVal[0]) + ' - ' + this.widthFracDen(eachVal[1])
      } else {
        if (this.availableFilters[key] !== undefined) {
          let obj = this.availableFilters[key].find((o) => o.value === val || o.id === val);
          return obj !== undefined ? obj.name : "";
        }
      }
    },
    removeFilterByValue(key, val) {
      if (key === "Code") {
        this.filters[key] = "";
        this.selectedSearch = "";
        this.isLoading = false
      } else {
        this.filters[key].splice(this.filters[key].indexOf(val), 1);
      }
    },
    search(data) {
      this.filters = {...this.filters, ...data};
    },
    fbimgCheck() {
      if (!this.isFrameBuilder) {
        return '';
      }
      return this.filters.type == 1 ? false : this.isFrameBuilder
    },
    searchProducts() {
      //this.filters.page = 1
      this.infShowLoader = true
      this.page = 1;
      this.$store
          .dispatch("product/getProductData", {
            options: {
              ...this.filters,
              page: this.page,
              fbimg: this.fbimgCheck(),
              frame_creator_active: this.fbimgCheck() ? true : 'all',
              items_per_page: this.perPage,
            },
            clear: true,
          })
          .then(data => {
            this.hasMore = data?.next_page_url == null ? false : true
            this.totalResults = data.total
            this.infShowLoader = false
            this.isLoading = false
            if (this.Open == 'Y' && !this.hasOpen) {
              if (data.data[0] !== undefined) {
                this.addToCart(data.data[0]);
                this.hasOpen = true;
              }
            }
            if (this.filters.frequently_purchased[0]) {
              this.removeInfScroll();
              this.runWatch = false
              this.filters.frequently_purchased = []
              let self = this
              setTimeout(function () {
                self.runWatch = true
              }, 1000)
            } else {
              this.loadInfScroll();
            }
          });
    },
    closeModal() {
      this.myModal.hide();
    },
    boRespond() {
      this.backorderModal.hide()
      this.frameMouldingBuilderSubmit(this.thisSaveItem);
    },
    frameMouldingBuilder(item) {
      if (this.isLoggedin) {
        this.$store.dispatch('product/getProductLocationQty', item.Code).then(data => {
          let pLocQty = data.LocationQty
          let onHand = 0;
          let inStock = false;
          Object.keys(pLocQty).forEach(key => {
            onHand = parseFloat(pLocQty[key].Onhand)
            if (1 > onHand) {
              console.log('not in stock')
            } else {
              inStock = true;
            }
          });
          if (inStock) {
            this.frameMouldingBuilderSubmit(item);
          } else {
            //display popup
            this.thisSaveItem = item
            this.backorderModal.show()
          }
        });
      } else {
        this.frameMouldingBuilderSubmit(item);
      }

    },
    frameMouldingBuilderSubmit(item) {
      item.id = item.Code;
      let obj = {};
      if (item.FrameType === "MATS") {
        obj.mat = item;
      } else if (item.FrameType === "GLASS") {
        obj.glass = item;
      } else {
        obj.moulding = item;
        obj.fSize = item.Width;
      }
      this.$emit("frameBuilder", obj);
    },
    addToCart(item) {
      this.detailCode = item.Code;
      this.$refs.cartD.loadProductDetails(item.Code);
      this.myModal.show();
      this.detailImg = this.imageUrl(item);
      //this.$router.push({name: 'productDetail', params: {Code}})
    },
    clearDetails() {
      this.detailData = {};
      this.detailImg = "";
      this.details = {};
    },
    shopDetail(item) {
      //todo do stuff with this.
      if (this.isLoggedin) {
        this.$refs.cartD.loadProductDetails(item.Code);
      }
      item.widthFrac = this.widthFrac(item.Width);
      this.detailImg = this.imageUrl(item);
      this.details = item;
      this.detailModal.show();
    },
    returnData(data) {
      this.detailData = data
    },
    widthFracDen(decWidth) {
      let f = new Fraction(decWidth);
      let res = f.toFraction(true);

      return res;
    },
    widthFrac(decWidth) {
      let f = new Fraction(decWidth);
      let res = f.toFraction(true);
      let resx = res.split(" ");

      let den = "";
      if (resx[1] !== undefined) {
        den = resx[1];
      }
      let fr = resx[0] + ' ' + den;
      return fr.replace(/\s+$/, '') + '"';
    },
    removeInfScroll() {
      window.onscroll = null
    },
    loadNextPage() {
      this.infShowLoader = true
      this.page = this.page + 1;
      this.$store
          .dispatch("product/getProductData", {
            options: {
              ...this.filters,
              page: this.page,
              fbimg: this.fbimgCheck(),
              frame_creator_active: this.fbimgCheck() ? true : 'all',
              items_per_page: this.perPage,
            },
          })
          .then(data => {
            this.hasMore = data?.next_page_url == null ? false : true
            this.infShowLoader = false
            this.totalResults = data.total
          });
    },
    loadInfScroll() {
      if (window.onscroll === null) {
        window.onscroll = () => {
          let bottomOfWindow =
              document.documentElement.scrollTop + window.innerHeight ===
              document.documentElement.offsetHeight;

          if (bottomOfWindow) {
            this.loadNextPage();
          }
        };
      }
      let self = this
      const modal = document.querySelector(".modal-body");
      modal.addEventListener("scroll", function () {
        if (modal.scrollTop + modal.clientHeight >= modal.scrollHeight) {
          self.loadNextPage();
        }
      });
    },
  },

  mounted() {
    if (this.Code !== undefined && this.Code != "") {
      this.filters.type = [];
      this.filters.Code = this.Code;
    } else if (!this.hasQuery) {
      //set default
      this.runWatch = false
      this.filters.type = [4]
    }

    if (this.$route.query.collection !== undefined && Object.keys(this.$route.query.collection).length !== 0) {
      this.hasQuery = true
      if (!Array.isArray(this.$route.query.collection)) {
        this.$route.query.collection = [this.$route.query.collection]
      }

      this.filters.collection = this.$route.query.collection.map(function (element) {
        return parseInt(element);
      });
    }
    this.myModal = new Modal(document.getElementById("cartModal"));
    this.detailModal = new Modal(document.getElementById("shopDModal"));
    this.backorderModal = new Modal(document.getElementById("backOrderModal"));
    this.$store.dispatch("product/getFilters", {options: {}}).then(() => {
      this.loadInfScroll()
    })
  }
};
</script>

<style scoped>

.adtlSF {
  border: 1px solid #cccccc;
  margin-left: 2px;
  margin-right: 2px;
  font-size: 12px;
}

.adtlSFLbl {
  position: relative;
  padding-left: 5px;
  margin-left: 2px;
  margin-right: 2px;
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  font-size: 12px;
  background-color: #cfe2ff;
  margin-bottom:15px;
}

.form-check-input {
  border-color: #000000;
}

.accordion-button-cust {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}

.dispH {
  height: 30px;
}

.codeFS {
  font-size: 2.8vh;
}


</style>
