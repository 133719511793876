import {createRouter, createWebHashHistory} from "vue-router";
import HomeView from "../views/HomeView.vue";
import store from "@/store";
import Login from "../components/Login";
import NotFoundPage from "@/components/NotFoundPage";
import FrameBuilderFE from "@/components/FrameBuilderFE";
import userSettings from "@/components/Admin/userSettings";
import cart from "@/components/Admin/viewCart";
import productSearch from "@/components/ProductSearch";
import productDetails from "@/components/ProductDetails";
import orderComplete from "@/components/Admin/orderComplete";
import userListOrders from "@/components/Admin/userListOrders";
import orderView from "@/components/Admin/orderView";
import whereToBuy from "@/views/whereToBuy.vue";
import whoWeAre from "@/views/whoWeAre.vue";
import shop from "@/views/shop.vue";
import privacyPolicy from "@/views/privacyPolicy.vue";
import Terms from "@/views/Terms.vue";
import storeLocations from "@/views/StoreLocations.vue";
import contactUs from "@/views/ContactUs.vue";
import FAQView from "@/views/FAQView.vue";
import careersView from "@/views/careersView.vue";
import AdminView from "@/components/Admin/AdminView.vue";
import OrderDetails from "@/components/Admin/OrderDetails.vue";
import InvoiceDetails from "@/components/Admin/InvoiceDetails.vue";
import VerifyData from "@/components/verifyData.vue";
import ForgotPassword from "@/components/ForgotPassword.vue";
import ResetPassword from "@/components/ResetPassword.vue";
import PdfList from "@/components/Admin/PdfList.vue";
import PaymentBatch from "@/components/Admin/PaymentBatch.vue";
import FrequentlyPurchased from "@/components/Admin/FrequentlyPurchased.vue";
import Credits from "@/components/Admin/Credits.vue";
import AccountMsg from "@/views/AccountMsg.vue";
import newAccount from "@/views/newAccount.vue";

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
    },
    {
        path: "/login/:extoken?",
        name: "Login",
        props: true,
        component: Login,
    },
    {
        path: "/accountMsg/:msg?/:msgHeading?",
        name: "AccountMsg",
        props: true,
        component: AccountMsg,
    },
    {
        path: '/forgot_password',
        name: 'ForgotPassword',
        component: ForgotPassword
    },
    {
        path: '/reset-password/:token/:email',
        name: 'ResetPassword',
        component: ResetPassword,
        props: true
    },
    {
        path: "/careers",
        name: "careers",
        component: careersView,
    },
    {
        path: "/FAQ",
        name: "FAQ",
        component: FAQView,
    },
    {
        path: "/contactUs",
        name: "contactUs",
        component: contactUs,
    },
    {
        path: "/storeLocations",
        name: "storeLocations",
        component: storeLocations,
    },
    {
        path: "/shop/:Code?/:Open?",
        name: "shop",
        component: shop,
        props: true,
    },
    {
        path: "/privacyPolicy",
        name: "privacyPolicy",
        component: privacyPolicy,
    },
    {
        path: "/Terms",
        name: "Terms",
        component: Terms,
    },
    {
        path: "/whoWeAre",
        name: "whoWeAre",
        component: whoWeAre,
    },
    {
        path: "/whereToBuy",
        name: "whereToBuy",
        component: whereToBuy,
    },
    {
        path: "/FrameBuilder",
        name: "FrameBuilder",
        component: FrameBuilderFE,
    },
    {
        path: "/orderComplete",
        name: "orderComplete",
        component: orderComplete,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/userListOrders",
        name: "userListOrders",
        component: userListOrders,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/pdflist",
        name: "pdflist",
        component: PdfList,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/newAccount",
        name: "newAccount",
        component: newAccount,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/orderView/:orderid",
        name: "orderView",
        component: orderView,
        props: true,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/userSettings",
        name: "userSettings",
        component: userSettings,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/productSearch",
        name: "productSearch",
        component: productSearch,
    },
    {
        path: "/productDetail/:Code",
        name: "productDetail",
        props: true,
        component: productDetails,
    },
    {
        path: '/verifyData',
        name: 'verifyData',
        component: VerifyData,
        props: true
    },
    {
        path: "/cart",
        name: "cart",
        component: cart,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/Admin",
        component: AdminView,
        children: [
            {
                path: "userListOrders/:searchx?",
                name: "userListOrders",
                component: userListOrders,
                props: true,
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'PaymentBatch',
                name: 'PaymentBatch',
                component: PaymentBatch,
                props: true
            },
            {
                path: "orderView/:orderid",
                name: "orderView",
                component: orderView,
                props: true,
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: "userSettings",
                name: "userSettings",
                component: userSettings,
                props: true,
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'OrderDetails/:orderid',
                name: 'OrderDetails',
                component: OrderDetails,
                props: true
            },
            {
                path: 'InvoiceDetails/:invoiceid',
                name: 'InvoiceDetails',
                component: InvoiceDetails,
                props: true
            },
            {
                path: 'FrequentlyPurchased',
                name: 'FrequentlyPurchased',
                component: FrequentlyPurchased,
                props: true
            },
            {
                path: 'Credits',
                name: 'Credits',
                component: Credits,
                props: true
            },
        ],
        meta: {
            requiresAuth: true,
        },
    },
    {
        // path: "*",
        path: "/:catchAll(.*)",
        name: "NotFound",
        component: NotFoundPage,
        meta: {
            requiresAuth: false,
        },
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach(async (to, from, next) => {
    document.title = "Ampf";

    /*
    if(store.getters['userAccess/accessToken']) {
      console.log('run auth');
      await store.dispatch('userAccess/isAuthenticated');
    }
    */

    if (localStorage.getItem('vtoken') !== null &&
        to.path !== '/verifyData' && to.path !== '/login') {
        store.dispatch('userAccess/clearVToken')
        next({name: "home"});
    } else {

        if (!to.meta.requiresAuth) {
            next();
        } else if (
            to.meta.requiresAuth &&
            !!store.getters["userAccess/accessToken"]
        ) {
            next();
        } else {
            next({name: "Login"});
        }
    }
});

/*
router.beforeEach((to, from, next) => {
  document.title = 'Ampf'

  //if (to.name === 'Login' || to.name === 'Home' || to.name === 'guestSignup' || to.name === 'NotFound' || to.name === 'sponsorSignup') {
  if (!to.meta.requiresAuth) {
    console.log('here 2');
    store.dispatch('userAccess/isAuthenticated').then(() => {
      next()
    }).catch(() => {
      next()
    })

  } else {
    store.dispatch('userAccess/isAuthenticated')
        .then(user => {
          console.log('ud',user);
          //store redirect in localstorage

          //var rdt = localStorage.getItem('redirectTo');
          //if(rdt !== undefined && rdt !== null){
            //console.log('then 2');
            //localStorage.removeItem('redirectTo')
            //router.push(rdt)
          //}else {


          if (to.matched.some(route => route.meta.requiresAuth)) {
            if (user) {
              next()
            } else {
              next({name: 'Login'})
            }
          } else {
            if (user) {
              next()
            } else {
              next({name: 'Login'})
            }
          }
          //}
        }).catch(err => {
      if (err.logout) {
        store.dispatch('userAccess/logout').then(() => {
          next({name: 'Login'})
        })
      } else {
        //store redirect in session variable
        if (window.location.pathname != '' && window.location.pathname != '/login') {
          localStorage.setItem('redirectTo', window.location.pathname)
        }
        next({name: 'Login'})
      }
    })
  }
})
*/

export default router;
