<template>
  <!-- Modal -->
  <div class="modal fade" :id="modalName" tabindex="-1" :aria-labelledby="modalName+'Label'" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-body p-4">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-5">
                    <!--
                    <span class="smaller-text-uppercase text-wrap">
                      {{ display_name }}
                    </span>
                    -->
                <div class="prod_img">
                  <img :src="detailImg" @error="imageUrlAlt" class="img-fluid">
                </div>

                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane"
                            type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Pricing
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane"
                            type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Product Details
                    </button>
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab"
                       tabindex="0">

                    <ul class="prod-details mt-3" v-if="getFrameType(Code) === 'MOULDING'">
                      <li>Length Price<span class="fw-semibold ms-3">${{
                          isNaN(lengthPriceF) ? 0 : lengthPriceF
                        }}</span></li>
                      <li>Chop Price <span class="fw-semibold ms-3">${{ isNaN(chopPriceF) ? 0 : chopPriceF }}</span>
                      </li>
                      <li>Join Price <span class="fw-semibold ms-3">${{ isNaN(joinPriceF) ? 0 : joinPriceF }}</span>
                      </li>
                      <li>Exact Price <span class="fw-semibold ms-3">${{
                          isNaN(exactPriceF) ? 0 : exactPriceF
                        }}</span></li>
                      <li>Box Price <span class="fw-semibold ms-3">${{
                          isNaN(boxPrice) ? 0 : boxPrice
                        }}</span></li>
                    </ul>
                    <ul class="prod-details mt-3" v-if="getFrameType(Code) === 'HARDWARE'">
                      <li>Price <span class="fw-semibold ms-3">${{
                          isNaN(hardwarePriceF) ? 0 : hardwarePriceF
                        }}</span></li>
                    </ul>
                    <ul class="prod-details mt-3" v-if="getFrameType(Code) === 'GLASS'">
                      <li>Price <span class="fw-semibold ms-3">${{
                          isNaN(glassPriceF) ? 0 : glassPriceF
                        }}</span></li>
                    </ul>
                    <ul class="prod-details mt-3" v-if="getFrameType(Code) === 'MATS'">
                      <li>Price <span class="fw-semibold ms-3">${{
                          isNaN(matPriceF) ? 0 : matPriceF
                        }}</span></li>
                    </ul>
                  </div>

                  <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab"
                       tabindex="0">


                    <ul class="prod-details mt-3">
                      <li v-if="getFrameType(Code) === 'MOULDING'"><span class="fw-semibold me-3">Width</span> {{ toFraction(width) }}</li>
                      <li v-if="getFrameType(Code) === 'MOULDING'"><span class="fw-semibold me-3">Finish </span> {{ finish }}</li>
                      <li><span class="fw-semibold me-3">Box Quantity </span>{{ boxqty }}</li>
                      <li v-if="getFrameType(Code) === 'MOULDING'"><span class="fw-semibold me-3">Rabbet</span> {{ rabbet }}</li>
                      <li v-if="getFrameType(Code) === 'MOULDING'"><span class="fw-semibold me-3">Styles</span>{{ styles }}</li>
                      <li v-if="getFrameType(Code) === 'MOULDING'"><span class="fw-semibold me-3">Collection</span>{{ collection }}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-7 ms-auto">
                <button type="button" class="btn-close position-absolute" data-bs-dismiss="modal"
                        stylearia-label="Close"></button>
                <h3 class="fw-semibold">Item Number: {{ Code }}</h3>
                <span class="smaller-text-uppercase">{{ display_name }}</span>

                <form class="product__form">
                  <div class="mb-3" v-if="productType == 4">
                    <label class="form-label d-block">Type</label>

                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="inlineRadioOptions" id="radioLength"
                             @change="typeChange('length')"
                             value="length"
                             checked
                      >
                      <label class="form-check-label" for="inlineRadio1">Length</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="inlineRadioOptions" id="radioChop"
                             @change="typeChange('chop')"
                             value="chop">
                      <label class="form-check-label" for="inlineRadio2">Chop</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="inlineRadioOptions" id="radioJoin"
                             @change="typeChange('join')"
                             value="join">
                      <label class="form-check-label" for="inlineRadio3">Join</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="inlineRadioOptions" id="radioExact"
                             @change="typeChange('exact')"
                             value="exact">
                      <label class="form-check-label" for="inlineRadio3">Exact</label>
                    </div>
                    <div class="form-check form-check-inline" v-if="boxqty > 0">
                      <input class="form-check-input" type="radio" name="inlineRadioOptions" id="radioExact"
                             @change="typeChange('box')"
                             value="box">
                      <label class="form-check-label" for="inlineRadio3">Box</label>
                    </div>
                  </div>

                  <div class="mb-3">

                    <label class="form-label">Location (Qty Available)</label>
                    <select class="form-select" aria-label="Default select example" v-model="productSelection.location"
                            @change="lookupStock">
                      <option v-for="(Loc, locidx) in locations" :key="locidx" :value="locidx"
                              :disabled="locationQty[locidx+'Name'] === ''">
                        {{ Loc.name }} - {{ locationQty[locidx + 'Onhand'] }} {{getLocationQtyLbl(Code)}}
                      </option>

                    </select>
                  </div>

                  <div class="mb-3" v-if="productSelection.type === 'length' && productType === 4 || productSelection.type === 'box'">
                    <label class="form-label">Length</label>
                    <div class="row">
                      <div class="col-lg-3">
                        <div class="input-group no-encroach mb-3">
                          <input type="number"
                                 min="0"
                                 step="1"
                                 class="form-control"
                                 placeholder=""
                                 aria-label="Length"
                                 v-model.number="productSelection.length"
                                 :disabled="productSelection.type === 'box'"
                                 @keyup="validateFullLengthInput();"
                                 @blur="lookupStock"/>
                        </div>
                      </div>
                      <div class="col-lg-4 mt-2">
                        Feet
                      </div>
                    </div>
                  </div>

                  <div class="mb-3" v-if="productSelection.type !== 'length' && productSelection.type !== 'box'">
                    <div class="row g-2">
                      <label class="form-label">Dimensions</label>

                      <div class="col-2">
                        <input type="number"
                               min="0"
                               step="1"
                               id="dimension[length]"
                               class="form-control"
                               name="dimension[length]"
                               v-model="productSelection.dimension.length"
                               @keyup="validateLengthInput(); calcType();"
                               @input="calcType()"
                        />
                      </div>
                      <div class="col-3">
                        <select class="form-select" id="dimension[length_fraction]" name="dimension[length_fraction]"
                                v-model="productSelection.dimension.length_fraction"
                                @change="calcType">
                          <option value=""></option>
                          <option value="1/16">1/16</option>
                          <option value="1/8">1/8</option>
                          <option value="3/16">3/16</option>
                          <option value="1/4">1/4</option>
                          <option value="5/16">5/16</option>
                          <option value="3/8">3/8</option>
                          <option value="7/16">7/16</option>
                          <option value="1/2">1/2</option>
                          <option value="9/16">9/16</option>
                          <option value="5/8">5/8</option>
                          <option value="11/16">11/16</option>
                          <option value="3/4">3/4</option>
                          <option value="13/16">13/16</option>
                          <option value="7/8">7/8</option>
                          <option value="15/16">15/16</option>
                        </select>
                      </div>
                      <div class="col-1">
                        <span class="mt-2 d-block fw-bold text-center">X</span>
                      </div>
                      <div class="col-2">
                        <input type="number"
                               min="0"
                               step="1"
                               id="dimension[width]"
                               class="form-control"
                               name="dimension[width]"
                               v-model="productSelection.dimension.width"
                               @keyup="validateWidthInput(); calcType();"
                               @input="calcType()"
                        />
                      </div>
                      <div class="col-3">
                        <select class="form-select" id="dimension[width_fraction]" name="dimension[width_fraction]"
                                v-model="productSelection.dimension.width_fraction"
                                @change="calcType">
                          <option value=""></option>
                          <option value="1/16">1/16</option>
                          <option value="1/8">1/8</option>
                          <option value="3/16">3/16</option>
                          <option value="1/4">1/4</option>
                          <option value="5/16">5/16</option>
                          <option value="3/8">3/8</option>
                          <option value="7/16">7/16</option>
                          <option value="1/2">1/2</option>
                          <option value="9/16">9/16</option>
                          <option value="5/8">5/8</option>
                          <option value="11/16">11/16</option>
                          <option value="3/4">3/4</option>
                          <option value="13/16">13/16</option>
                          <option value="7/8">7/8</option>
                          <option value="15/16">15/16</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="mb-3" v-if="productSelection.type !== 'length' || productType !== 4">
                    <label class="form-label">Quantity</label>
                    <div class="row">
                      <div class="col-lg-4">
                        <div class="input-group no-encroach mb-3">
                          <button class="btn btn-secondary fw-bold" type="button" id="button-addon1"
                                  @click="qtyInc('dec')">-
                          </button>
                          <input type="text" class="form-control" placeholder="0" aria-label="Quantity"
                                 aria-describedby="button-addon2"
                                 v-model.number="productSelection.quantity"
                                 @keyup="calcType(); qtyInc();"
                                 @input="numberOnly"
                          >
                          <button class="btn btn-secondary fw-bold" type="button" id="button-addon2"
                                  @click="qtyInc('inc')">+
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div :id="cartMsgName"></div>
                  <div id="isInStock" style="text-align:center;" v-show="isInStock == 1">
                    <span style="color:green; text-align:center;">In Stock</span>
                  </div>

                  <div class="mb-3">
                    <label class="form-label">Instructions</label>
                    <textarea class="form-control" aria-label="With textarea" v-model="productSelection.instructions"
                              placeholder="Add any specific instructions related to your order" rows="3"
                              @input="updateCount"
                    ></textarea>
                    <p>Characters remaining: <span style="color:green;">{{ charactersRemaining }}</span></p>
                  </div>

                  <div id="orderfail"></div>
                  <div id="orderconfirm">
                    <div id="backorderoptions" v-show="isInStock == 2">
                      <table>
                        <tr>
                          <td colspan="2">
                            <span style="font-weight:bold; padding:10px;">Ship:</span> <span id="ShipVal">{{ ShipVal }}</span>
                            <span style="font-weight:bold; padding:10px;">Backordered:</span><span id="BackOrderVal">{{
                              BackOrderVal
                            }}</span>
                            <span style="font-weight:bold; padding:10px;">Cancelled:</span> <span id="CancelledVal">{{
                              CancelledVal
                            }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td><input id="firstradio" type="radio" name="backtype" value="backremq"
                                     @change="backorderQtyUpdate('backremq')"/></td>
                          <td>Backorder remaining quantities</td>
                        </tr>
                        <tr>
                          <td><input type="radio" name="backtype" value="backcancel"
                                     @change="backorderQtyUpdate('backcancel')"/></td>
                          <td>Cancel remaining quantities</td>
                        </tr>
                        <tr>
                          <td><input type="radio" name="backtype" value="backallq"
                                     @change="backorderQtyUpdate('backallq')"/></td>
                          <td>Backorder all ordered quantities</td>
                        </tr>
                      </table>
                    </div>

                    <div class="d-grid gap-2">
                      <button class="btn btn-secondary btn-lg mb'2" type="button" @click="addToCart"><strong>Add to Cart</strong></button>
                    </div>
                  </div>

                </form>

              </div>
            </div>

          </div>
        </div>


      </div>
    </div>
  </div>

</template>

<script>
import {mapGetters} from "vuex";
import Fraction from "fraction.js";

export default {
  name: "cartDetails",
  props: ['detailImg','modalId'],
  data() {
    return this.initialState();
  },
  computed: {
    ...mapGetters({
      'productDetail': 'product/getProductDetail',
      'productLocationQty': 'product/getProductLocationQty'
    }),
  },
  watch: {
    'productSelection.type': function (type) {
        if(type === 'box'){
          this.productSelection.quantity = 1
          this.productSelection.length = this.boxqty
          this.lookupStock()
        }
    }
  },
  methods: {
    validateFullLengthInput() {
      this.productSelection.length = parseInt(this.productSelection.length)
      if (this.productSelection.length < 0) {
        this.productSelection.length = 0; // Reset to 0 if negative
      } else {
        this.productSelection.length =  Math.floor(this.productSelection.length); // Round down to the nearest integer
      }
    },
    validateWidthInput() {
      this.productSelection.dimension.width = parseInt(this.productSelection.dimension.width)
      if (this.productSelection.dimension.width <= 0) {
        this.productSelection.dimension.width = null; // Reset to 0 if negative
      } else {
        this.productSelection.dimension.width =  Math.floor(this.productSelection.dimension.width); // Round down to the nearest integer
      }
    },
    validateLengthInput() {
      this.productSelection.dimension.length = parseInt(this.productSelection.dimension.length)
      if (this.productSelection.dimension.length <= 0) {
        this.productSelection.dimension.length = null; // Reset to 0 if negative
      } else {
        this.productSelection.dimension.length =  Math.floor(this.productSelection.dimension.length); // Round down to the nearest integer
      }
    },
    numberOnly(event) {
      // Allow only digits
      const regex = /^[0-9]*$/;
      if (!regex.test(event.target.value)) {
        event.target.value = event.target.value.replace(/[^0-9]/g, '');
        this.integerValue = parseInt(event.target.value) || 0;
      }
    },
    qtyInc(dir){
      if(dir === 'dec'){
        this.productSelection.quantity > 0 ? this.productSelection.quantity-- : 0
      }else if(dir === 'inc'){
        this.productSelection.quantity++
      }

      if(this.productSelection.type === 'box'){
        this.productSelection.length = (this.productSelection.quantity * this.boxqty)
        this.lookupStock();
      }
      this.calcType();
    },
    getLocationQtyLbl(Code){
      return this.getFrameType(Code) === 'MOULDING' ? 'ft' : 'qty'
    },
    getFrameType(Code){
      let ftype = ''
      if(Code !== undefined && Code !== '' &&
          this.productDetail.products !== undefined &&
          this.productDetail?.products[Code] !== undefined
      ) {
        ftype = this.productDetail.products[Code].FrameType
      }
      return ftype
    },
    toFraction(frac){
      if(frac !== null && frac !== undefined && frac !== '') {
        let x = new Fraction(frac);
        return x.toFraction(true); // String "1 22/25"
      }
      return frac
    },
    updateCount() {
      this.charactersRemaining = this.maxLength - this.productSelection.instructions.length;
      if (this.charactersRemaining < 0) {
        this.productSelection.instructions = this.productSelection.instructions.slice(0, this.maxLength);
        this.charactersRemaining = 0;
      }
    },
    resetState() {
      return new Promise((resolve) => {
        Object.assign(this.$data, this.initialState());
        this.modalName = this.modalId ?? 'cartModal'
        this.cartMsgName = this.modalId === 'frameModal' ? 'frameMsg': 'cartMsg'
        resolve()
      })
    },
    initialState() {
      return {
        modalName: 'cartModal',
        cartMsgName: 'cartMsg',
        maxLength: 50,
        charactersRemaining: 50,
        defaultImg: require("@/assets/images/img-default.png"),
        Code: '',
        finish: '',
        width: '',
        rabbet: '',
        styles: '',
        hasDiscount: false,
        discountObj: {},
        orglengthPriceF: 0,
        hInt: 0,
        wInt: 0,
        lengthPriceF: 0,
        chopPriceF: 0,
        orgchopPriceF: 0,
        orgjoinPriceF: 0,
        joinPriceF: 0,
        exactPriceF: 0,
        orgexactPriceF: 0,
        orghardwarePriceF: 0,
        hardwarePriceF: 0,
        orgglassPriceF: 0,
        glassPriceF: 0,
        matPriceF: 0,
        orgmatPriceF: 0,
        orgreadyPriceF: 0,
        readyPriceF: 0,
        boxqty: 0,
        boxPrice: 0,
        collection: '',
        joinfee: 0,
        joinosfee: 0,
        yourLength: '',
        yourLengthV: 0,
        customLength: '',
        productWidth: 0,
        isInStock: 0,
        backremq: 0,
        backcancel: 0,
        backallq: 0,
        backtype: '',
        ShipVal: 0,
        onhand: 0,
        BackOrderVal: '',
        CancelledVal: '',
        ShipOrder: 0,
        maxins: 0,
        display_name: '',
        locations: {
          'B': {'name': 'Greensboro'},
          'C': {'name': 'New Orleans'},
          'E': {'name': 'Tampa'},
          'G': {'name': 'Denver'},
        },
        productType: 0,
        locationQty: {
          GName: '',
          GOnhand: 0,
          BName: '',
          BOnhand: 0,
          CName: '',
          COnhand: 0,
          EName: '',
          EOnhand: 0,
          acctLoc1: '',
          acctLoc1Name: '',
          defaultonhand1Raw: 0,
          defaultonhand1: 0
        },
        productSelection: {
          type: 'length',
          instructions: '',
          length: 0,
          quantity: 0,
          location: '',
          dimension: {
            length: 0,
            length_fraction: 0,
            width: 0,
            width_fraction: 0,
            unitedInches: 0
          }
        }
      }
    },
    imageUrl() {
      let loc = '';
      if (this.productDetail.products !== undefined) {
        let item = this.productDetail.products[this.Code];
        if (item !== undefined) {
          loc = process.env.VUE_APP_CDN + "/frames/" + item.Code + "/" + item.Code + "-small.jpg";
          if (item.FrameType === 'MATS') {
            loc = process.env.VUE_APP_CDN + "/mat/" + item.ManufCode + "/" + item.ManufCode + "-small.jpg";
          }
        }
      }
      return loc;
    },
    imageUrlAlt(event) {
      event.target.src = this.defaultImg
    },
    instructionsCount() {
      this.maxins = this.productSelection.instructions.length
    },
    backorderQtyUpdate(bktype) {
      this.backtype = bktype || ''
      let onhand = this.onhand
      let backremq = this.backremq
      let backcancel = this.backcancel
      let backallq = this.backallq

      this.BackOrderVal = '0'
      this.CancelledVal = '0'

      switch (bktype) {
        case 'backremq':
          this.BackOrderVal = backremq.toFixed(2)
          this.ShipVal = onhand.toFixed(2)
          //$('#backremq').val(backremq.toFixed(2));
          this.ShipOrder = onhand.toFixed(2)
          break;
        case 'backcancel':
          //$('#backcancel').val(backcancel.toFixed(2));
          this.CancelledVal = backcancel.toFixed(2)
          this.ShipVal = onhand.toFixed(2)
          this.ShipOrder = onhand.toFixed(2)
          break;
        case 'backallq':
          //$('#backallq').val(backallq.toFixed(2));
          this.BackOrderVal = backallq.toFixed(2)
          this.ShipVal = 0
          this.ShipOrder = 0
          break;
        default:
          this.BackOrderVal = backremq.toFixed(2)
          //$('#backremq').val(backremq.toFixed(2));
          this.ShipOrder = onhand.toFixed(2)
      }
    },
    lookupStock() {
      document.getElementById(this.cartMsgName).innerHTML = '';
      this.isInStock = 0
      if (this.productSelection.location && this.productLocationQty.LocationQty[this.productSelection.location] !== undefined) {
        let lenamount = parseFloat(this.productSelection.length)
        this.onhand = parseFloat(this.productLocationQty.LocationQty[this.productSelection.location].Onhand)
        if (lenamount > this.onhand) {
          //not in stock
          this.isInStock = 2
          this.backremq = (lenamount - this.onhand);
          this.backcancel = this.backremq;
          this.backallq = lenamount;
          this.ShipVal = this.onhand.toFixed(2)
          this.backorderQtyUpdate()
        } else {
          //in stock
          this.isInStock = 1
        }
      }
    },
    typeChange(type) {
      this.productSelection.type = type
      this.clearData()
    },
    calcUnitedInch() {
      this.productSelection.dimension.unitedInches = (parseInt(this.productSelection.dimension.width) + parseInt(this.productSelection.dimension.length))
    },
    addToCart() {
      let pass = true
      let msg = ''
      if(this.productType === 1 || this.productType === 2 || this.productType === 3 || this.productType === 5){
        if(this.productSelection.quantity <= 0){
          pass = false
          msg = 'Quantity must be greater then 0'
        }
      }else if(this.productType === 4){

        //check length
        if(((this.productSelection.type === 'chop' ||
            this.productSelection.type === 'join' ||
            this.productSelection.type === 'exact'
            )
            && this.productSelection.quantity <= 0) ||
            ((this.productSelection.type === 'chop' ||
              this.productSelection.type === 'join' ||
              this.productSelection.type === 'exact'
            )
            && this.productSelection.dimension.length <= 0 &&
               this.productSelection.dimension.width <= 0
            )
        ){
          pass = false
          msg = 'Quantity and Dimensions are required'
        }else if(this.productSelection.type === 'box' && this.productSelection.quantity <= 0){
          pass = false
          msg = 'Quantity must be greater then 0'
        }else if(this.productSelection.type === 'length' && this.productSelection.length <= 0){
          pass = false
          msg = 'Length must be greater then 0'
        }else if((this.productSelection.type === 'chop' ||
                 this.productSelection.type === 'join' ||
                 this.productSelection.type === 'exact') &&
                 (this.productSelection.dimension.length < 6 ||
                  this.productSelection.dimension.width < 6)){
          pass = false
          msg = 'The smallest size can be 6"'
        }
      }

      if(this.isInStock == 2){
        //not in stock make sure backtype is filled in
        if(this.backtype === undefined || this.backtype === ''){
          pass = false
          msg = 'Please select a back order option';
        }
      }

      if(pass) {
        document.getElementById(this.cartMsgName).innerHTML = '';
        this.$store.dispatch('cart/addToCart', {Code: this.Code, cartdata: this.$data}).then(() => {
          localStorage.setItem('lastCartActivityTime', Date.now());
          this.$emit('closeModal')
        })
      }else{
        document.getElementById(this.cartMsgName).innerHTML = "<span style='color:red;'>"+msg+"</span>";
      }
    },
    clearData() {

      this.productSelection.length = 0
      this.productSelection.quantity = 0
      this.productSelection.dimension.length = null
      this.productSelection.dimension.length_fraction = 0
      this.productSelection.dimension.width = null
      this.productSelection.dimension.width_fraction = 0
      //todo hide back order options


    },
    roundHalf(value) {
      var number = .5;
      //var ceil = Math.ceil(value);
      var remainder = value % number;
      if (remainder > 0)
        value = value - remainder + number;
      return value;
    },
    calcType() {
      document.getElementById(this.cartMsgName).innerHTML = '';
      let pData = this.productDetail.products[this.Code]
      if (this.productDetail.discounts !== undefined && this.productDetail.discounts[this.Code] !== undefined) {
        this.joinfee = this.productDetail.discounts[this.Code].JoinFee;
        this.joinosfee = this.productDetail.discounts[this.Code].JoinOSFee;
      }
      let lType = this.productSelection.type;
      let ptype = pData.type
      let qty = this.productSelection.quantity

      let n = new Object();
      n.value = qty;
      n.noV = true;
      //lookupStock(n);

      if (ptype != 4) {
        if (qty >= 1) {
          this.customLength = '1'
          //$('#add_to_cart').removeAttr("disabled");
          //$('#add_to_cart').removeClass("disabled");
        } else {
          this.customLength = ''
          //$('#add_to_cart').attr("disabled", true);
          //$('#add_to_cart').addClass("disabled");
        }
        return;
      }

      //alert('test');
      if (qty == "") {
        qty = 1;
      }
      let dimLen = this.productSelection.dimension.length || 0;
      let dimLenFrac = this.productSelection.dimension.length_fraction;
      let dimFullLenV = 0

      if (dimLenFrac != "" && dimLen != "") {
        let dimFullLen = dimLen + ' ' + dimLenFrac;
        let l = new Fraction(dimFullLen);
        dimFullLenV = l.valueOf();
      } else {
        dimFullLenV = parseInt(dimLen);
      }


      let dimWidth = this.productSelection.dimension.width || 0
      let dimWidthFrac = this.productSelection.dimension.width_fraction

      let unitedInches = (parseInt(dimWidth) + parseInt(dimLen));
      this.productSelection.dimension.unitedInches = unitedInches
      let dimFullWidthV = 0;

      if (dimWidthFrac != "" && dimWidth != "") {
        let dimFullWidth = dimWidth + ' ' + dimWidthFrac;
        let w = new Fraction(dimFullWidth);
        dimFullWidthV = w.valueOf();
      } else {
        dimFullWidthV = parseInt(dimWidth);
      }

      let productWidth = this.width
      let pw = new Fraction(productWidth);
      let productWidthV = pw.valueOf();
      this.productWidth = productWidthV

      let totalFT1 = 0;
      let totalFT2 = 0;
      let totalFT3 = 0;
      let totalFT = 0;
      let totalFTQTY = 0;
      if (dimFullLenV != "" && !isNaN(dimFullLenV) &&
          dimFullWidthV != "" && !isNaN(dimFullWidthV)
      ) {

        totalFT1 = (dimFullLenV + dimFullWidthV) * 2;
        totalFT2 = (8 * productWidthV);
        totalFT3 = (totalFT1 + totalFT2);
        totalFT = (totalFT3 / 12);
        totalFTQTY = (this.roundHalf(totalFT) * qty);


        switch (lType) {
          case 'chop':
          case 'join':
            if (totalFTQTY < 4) {
              totalFTQTY = 4;
            }
            break;
        }

        this.yourLength = totalFTQTY + ' FT'
        this.yourLengthV = totalFTQTY
        //$('#add_to_cart').removeAttr("disabled");
        //$('#add_to_cart').removeClass("disabled");

      } else {
        //$('#add_to_cart').attr("disabled", true);
        //$('#add_to_cart').addClass("disabled");
        //$('#yourLengthV').val('');
        this.yourLengthV = 0
      }
    },
    async loadProductDetails(Code) {
      await this.resetState()
      return new Promise((resolve) => {
        this.Code = Code
        this.$store.dispatch('product/getProductLocationQty', this.Code).then(() => {
          this.locationQty.acctLoc1 = this.productLocationQty.AccountLocs.Location
          this.locationQty.acctLoc1Name = this.productLocationQty.AccountLocs.Name
          this.locationQty.defaultonhand1Raw = 0
          if (this.productLocationQty.LocationQty[this.locationQty.acctLoc1] != undefined) {
            this.productSelection.location = this.productLocationQty.AccountLocs.Location
            this.locationQty.defaultonhand1Raw = parseFloat(this.productLocationQty.LocationQty[this.locationQty.acctLoc1].Onhand);
            //if(this.locationQty.acctLoc1 !== 'G'){
              //delete this.locations['G'];
            //}
          }
          this.locationQty.defaultonhand1 = Math.round(this.locationQty.defaultonhand1Raw);

          if (this.productLocationQty.LocationQty['B'] != undefined) {
            let BlocRaw = this.productLocationQty.LocationQty['B'];
            this.locationQty.BOnhand = Math.round(parseFloat(BlocRaw.Onhand) - parseFloat(BlocRaw.CommitAmt));
            this.locationQty.BName = BlocRaw.Name;
          }
          if (this.productLocationQty.LocationQty['C'] != undefined) {
            let ClocRaw = this.productLocationQty.LocationQty['C'];
            this.locationQty.COnhand = Math.round(parseFloat(ClocRaw.Onhand) - parseFloat(ClocRaw.CommitAmt));
            this.locationQty.CName = ClocRaw.Name
          }
          if (this.productLocationQty.LocationQty['E'] != undefined) {
            let ElocRaw = this.productLocationQty.LocationQty['E'];
            this.locationQty.EOnhand = Math.round(parseFloat(ElocRaw.Onhand) - parseFloat(ElocRaw.CommitAmt));
            this.locationQty.EName = ElocRaw.Name;
          }
          if (this.productLocationQty.LocationQty['G'] != undefined) {
            let GlocRaw = this.productLocationQty.LocationQty['G'];
            this.locationQty.GOnhand = Math.round(parseFloat(GlocRaw.Onhand) - parseFloat(GlocRaw.CommitAmt));
            this.locationQty.GName = GlocRaw.Name;
          }


        })

        this.$store.dispatch('product/getProductDetails', this.Code).then(async () => {
          if (this.productDetail.products[this.Code] == undefined) {
            return false
          }
          let prodData = this.productDetail.products[this.Code]

          if(prodData.FrameType !== 'MOULDING'){
            this.productSelection.quantity = 1
          }

          this.boxqty = prodData.box_qty
          this.boxnodiscount = prodData.box_nodiscount
          this.collection = prodData.collection_name
          this.finish = prodData.finish_name
          this.styles = prodData.style_name
          //this.width = prodData.width_display
          this.width = prodData.Width
          this.rabbet = prodData.rabbet
          this.productType = prodData.type
          this.display_name = prodData.display_name

          this.hInt = prodData.H_Int
          this.wInt = prodData.W_Int

          if (prodData.Vendor != undefined &&
              this.productDetail.discounts[prodData.Vendor] != undefined
          ) {
            this.discountObj = this.productDetail.discounts[prodData.Vendor];
            this.hasDiscount = true;
          }
          let len = parseFloat(prodData.Length);
          len = len.toFixed(2);
          this.orglengthPriceF = len

          let hasBoxPrice = false
          if(!this.boxnodiscount && this.boxqty > 0 && len > 0) {
            this.boxPrice = (parseFloat(len) * .7)
            this.boxPrice = this.boxPrice.toFixed(2)
            hasBoxPrice = true
          }

          if (prodData.bypass != undefined && prodData.bypass == 'BYPASS') {
            //console.log('bypass');
          } else if (this.hasDiscount && this.discountObj['L'] != undefined) {
            let lendis = len * (parseFloat(this.discountObj['L'].DiscRate) / 100);
            len = (len - lendis);
            len = len.toFixed(2);
          } else if (prodData.FrameLine == "A" && this.productDetail.discounts['Length'] != undefined &&
              this.productDetail.discounts['Length'] != ""
          ) {
            len = (len - (len * parseFloat(this.productDetail.discounts['Length']) / 100));
            len = len.toFixed(2);
          } else if (prodData.FrameLine == "D" && this.productDetail.discounts['Denver_Length'] != undefined &&
              this.productDetail.discounts['Denver_Length'] != ""
          ) {
            len = (len - (len * parseFloat(this.productDetail.discounts['Denver_Length']) / 100));
            len = len.toFixed(2);
          }

          if(!hasBoxPrice){
            this.boxPrice = len
          }
          this.lengthPriceF = len

          let chop = parseFloat(prodData.Chop);
          chop = chop.toFixed(2);
          this.orgchopPriceF = chop
          if (prodData.bypass != undefined && prodData.bypass == 'BYPASS') {
            //console.log('bypass')
          } else if (this.hasDiscount && this.discountObj['C'] != undefined) {
            let chopdis = chop * (parseFloat(this.discountObj['C'].DiscRate) / 100);
            chop = (chop - chopdis);
            chop = chop.toFixed(2);
          } else if (prodData.FrameLine == "A" && this.productDetail.discounts['Chop'] != undefined &&
              this.productDetail.discounts['Chop'] != ""
          ) {
            chop = (chop - (chop * parseFloat(this.productDetail.discounts['Chop']) / 100));
            chop = chop.toFixed(2);
          } else if (prodData.FrameLine == "D" && this.productDetail.discounts['Denver_Chop'] != undefined &&
              this.productDetail.discounts['Denver_Chop'] != ""
          ) {
            chop = (chop - (chop * parseFloat(this.productDetail.discounts['Denver_Chop']) / 100));
            chop = chop.toFixed(2);
          }

          this.chopPriceF = chop

          let join = parseFloat(prodData.JoinAmt);
          join = join.toFixed(2);
          this.orgjoinPriceF = join
          if (prodData.bypass != undefined && prodData.bypass == 'BYPASS') {
            //console.log('bypass');
          } else if (this.hasDiscount && this.discountObj['J'] != undefined) {
            let joindis = join * (parseFloat(this.discountObj['J'].DiscRate) / 100);
            join = (join - joindis);
            join = join.toFixed(2);
          } else if (prodData.FrameLine == "A" && this.productDetail.discounts['Join'] != undefined &&
              this.productDetail.discounts['Join'] != ""
          ) {
            join = (join - (join * parseFloat(this.productDetail.discounts['Join']) / 100));
            join = join.toFixed(2);
          } else if (prodData.FrameLine == "D" && this.productDetail.discounts['Denver_Join'] != undefined &&
              this.productDetail.discounts['Denver_Join'] != ""
          ) {
            join = (join - (join * parseFloat(this.productDetail.discounts['Denver_Join']) / 100));
            join = join.toFixed(2);
          }

          this.joinPriceF = join

          let exact = parseFloat(prodData.Length2);
          exact = exact.toFixed(2);
          if (this.hasDiscount && this.discountObj['E'] != undefined) {
            let exactdis = exact * (parseFloat(this.discountObj['E'].DiscRate) / 100);
            exact = (exact - exactdis);
            exact = exact.toFixed(2);
          }

          this.exactPriceF = exact
          this.orgexactPriceF = exact


          let hware = parseFloat(prodData.Hardware);

          hware = hware.toFixed(2);
          this.orghardwarePriceF = hware
          if (prodData.bypass != undefined && prodData.bypass == 'BYPASS') {
            //console.log('bypass');
          } else if (this.hasDiscount && this.discountObj['H'] != undefined) {
            let hwaredis = hware * (parseFloat(this.discountObj['H'].DiscRate) / 100);
            hware = (hware - hwaredis);
            hware = hware.toFixed(2);
          } else if (this.productDetail.discounts['Hardware'] != undefined &&
              this.productDetail.discounts['Hardware'] != ""
          ) {
            hware = (hware - (hware * parseFloat(this.productDetail.discounts['Hardware']) / 100));
            hware = hware.toFixed(2);
          }
          this.hardwarePriceF = hware
          let glass = 0

          if (prodData.bypass != undefined && prodData.bypass == 'BYPASS') {
            //console.log('bypass');
            glass = parseFloat(prodData['Glass1']);
          } else if (this.productDetail.discounts['Glass'] == undefined || this.productDetail.discounts['Glass'] == " ") {
            glass = parseFloat(prodData['Glass1']);
          } else if (this.productDetail.discounts['Glass'] == "4") {
            glass = parseFloat(prodData['Mat5']);
          } else {
            glass = parseFloat(prodData['Glass' + this.productDetail.discounts['Glass']]);
          }
          glass = glass.toFixed(2);

          this.orgglassPriceF = glass

          if (this.hasDiscount && this.discountObj['G'] != undefined) {
            let glassdis = glass * (parseFloat(this.discountObj['G'].DiscRate) / 100);
            glass = (glass - glassdis);
            glass = glass.toFixed(2);
          }

          this.glassPriceF = glass

          let mat = 0

          if (prodData.bypass != undefined && prodData.bypass == 'BYPASS') {
            //console.log('bypass');
            mat = parseFloat(prodData['Mat1']);
          } else if (this.productDetail.discounts['FoamBoard'] == undefined || this.productDetail.discounts['FoamBoard'] == " ") {
            mat = parseFloat(prodData['Mat1']);
          } else {
            mat = parseFloat(prodData['Mat' + this.productDetail.discounts['FoamBoard']]);
          }
          mat = mat.toFixed(2);

          this.orgmatPriceF = mat
          if (this.hasDiscount && this.discountObj['M'] != undefined) {
            let matdis = mat * (parseFloat(this.discountObj['M'].DiscRate) / 100);
            mat = (mat - matdis);
            mat = mat.toFixed(2);
          }

          this.matPriceF = mat

          let ready = parseFloat(prodData.Readymade);
          ready = ready.toFixed(2);
          this.orgreadyPriceF = ready
          this.readyPriceF = ready

          await this.calcType()

          this.$emit('returnData', this.$data)
          resolve(this.$data)
        })
      })
    }
  },
  mounted() {
    this.modalName = this.modalId ?? 'cartModal'
    this.cartMsgName = this.modalId === 'frameModal' ? 'frameMsg': 'cartMsg'
  }
}
</script>

<style scoped>

</style>