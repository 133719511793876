<template>
  <div class="wrapper">
    <div class="content">
      <div class="container-fluid">
        <div class="row" v-if="!signin">
          <div class="col-md-12 mb-3 mt-1">
            <div class="card collapsed-card">
              <div class="card-header">
                <h3 class="card-title">Account Listing</h3>
              </div>
              <div class="card-body p-3">
                <div class="row">
                  <div class="col-4">
                    <label class="form-label">Account #</label>
                    <input type="text" class="form-control" :value="accountDetails?.Acctnum" autocomplete="off" readonly disabled/>
                  </div>
                  <div class="col-4">
                    <label class="form-label">Phone</label>
                    <input type="text" class="form-control" :value="accountDetails?.Phone" autocomplete="off" readonly disabled/>
                  </div>
                  <div class="col-4">
                    <label class="form-label">Shipvia</label>
                    <input type="text" class="form-control" :value="accountDetails?.Shipvia" autocomplete="off" readonly disabled/>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <label class="form-label">Company</label>
                    <input type="text" class="form-control" :value="accountDetails?.Company" autocomplete="off" readonly disabled/>
                  </div>
                  <div class="col-4">
                    <label class="form-label">Fax</label>
                    <input type="text" class="form-control" :value="accountDetails?.Fax" autocomplete="off" readonly disabled/>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="row">
                      <div class="col-12">
                        <label class="form-label">Address</label>
                        <input type="text" class="form-control mb-1" :value="accountDetails?.Address1" autocomplete="off" readonly
                               disabled/>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <input type="text" class="form-control mb-1" :value="accountDetails?.Address2" autocomplete="off" readonly
                               disabled/>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <input type="text" class="form-control mb-1"
                               :value="accountDetails?.City + ', ' +accountDetails?.State  + ' ' +accountDetails?.Zip"
                               autocomplete="off"
                               readonly
                               disabled/>
                      </div>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="row">
                      <div class="col-12">
                        <label class="form-label">Contact</label>
                        <input type="text" class="form-control mb-1" :value="accountDetails?.Contact" autocomplete="off" readonly disabled/>
                      </div>
                      <div class="col-12 mb-2">

                      </div>
                      <div class="col-12">
                        <div class="row">
                          <div class="col-8">
                            <label class="form-label">Email</label>
                            <input type="text" class="form-control" v-model="account.email" autocomplete="off"/>
                          </div>
                          <div class="col-3">
                            <button class="btn btn-primary p-1" style="margin-top:30px;" @click.prevent="updateAccount()">Update</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="row">
                      <div class="col-12">
                        <label class="form-label">Title</label>
                        <input type="text" class="form-control mb-1" :value="accountDetails?.Title" autocomplete="off" readonly disabled/>
                      </div>
                      <div class="col-12 mb-2">

                      </div>
                      <div class="col-12">
                        <label class="form-label">Website</label>
                        <input type="text" class="form-control mb-1" :value="accountDetails?.Website" autocomplete="off" readonly disabled/>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="!signin">
          <div class="col-md-12 mb-3 mt-1">
            <div class="card collapsed-card">
              <div class="card-header">
                <h3 class="card-title">Discount</h3>
              </div>
              <div class="card-body p-3">
                <div class="row">
                  <div class="col-4">
                    <label class="form-label">Matboard</label>
                    <input type="text" class="form-control" :value="discountDisp('mat',accountDetails?.B_Disc)" autocomplete="off" readonly
                           disabled/>
                  </div>
                  <div class="col-4">
                    <label class="form-label">Chop</label>
                    <input type="text" class="form-control" :value="discountDisp('chop',accountDetails?.C_Disc)" autocomplete="off" readonly
                           disabled/>
                  </div>
                  <div class="col-4">
                    <label class="form-label">Glass</label>
                    <input type="text" class="form-control" :value="discountDisp('glass',accountDetails?.G_Disc)" autocomplete="off" readonly
                           disabled/>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <label class="form-label">Join</label>
                    <input type="text" class="form-control" :value="discountDisp('join',accountDetails?.J_Disc)" autocomplete="off" readonly
                           disabled/>
                  </div>
                  <div class="col-4">
                    <label class="form-label">Length</label>
                    <input type="text" class="form-control" :value="discountDisp('length',accountDetails?.L_Disc)" autocomplete="off" readonly
                           disabled/>
                  </div>
                  <div class="col-4">
                    <label class="form-label">Hardware</label>
                    <input type="text" class="form-control" :value="discountDisp('hardware',accountDetails?.H_Disc)" autocomplete="off" readonly
                           disabled/>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="card collapsed-card mb-5">
              <div class="card-header">
                <h3 class="card-title">Billing Methods</h3>
                <div class="card-tools">
                  <button type="button" class="btn btn-tool" data-card-widget="collapse"
                          @click="billBtnShown ? billBtnShown=false : billBtnShown=true"><i
                      class="fas fa-plus fa-lg"></i></button>
                </div>
                <button type="button" class="btn btn-primary float-right" data-bs-toggle="modal"
                        data-bs-target="#methodModal">Add Billing Method
                </button>

              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <table class="table table-sm">
                  <thead>
                  <tr>
                    <th>Card Name</th>
                    <th>Last Four</th>
                    <th>Exp</th>
                    <th>Type</th>
                    <th>Default</th>
                    <th>&nbsp;</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="bill in accountDetails?.billing" :key="bill.id">
                    <td>{{ bill.name }}</td>
                    <td>{{ bill.last_four }}</td>
                    <td>{{ bill.expmonth + ' - ' + bill.expyear }}</td>
                    <td>{{ bill.type }}</td>
                    <td><input type="radio" name="billDefault" :checked="bill.default"
                               @click="updateDefault(bill.id)">
                    </td>
                    <td>
                      <button
                          type="submit"
                          class="btn btn-warning float-right me-4"
                          @click.prevent="editMethod(bill.id)"
                      >
                        Edit
                      </button>
                      <button type="submit" class="btn btn-danger float-right ml-3"
                              @click.prevent="deleteMethod(bill.id)">
                        Delete
                      </button>
                    </td>
                  </tr>

                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="modal fade" id="methodModal" tabindex="-1" role="dialog" aria-labelledby="methodModalLabel"
         aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="methodModalLabel">Billing Method</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">

            <div class="padding">
              <div class="row">
                <div class="col-sm-12">
                  <div class="card">
                    <div class="card-header">
                      <strong>Credit Card</strong>
                      <small class="ml-2">enter your card details</small>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="form-group">
                            <label for="name">Card Nickname</label>
                            <input
                                class="form-control"
                                id="nickname"
                                type="text"
                                placeholder="Nickname"
                                v-model="form.name"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="form-group">

                            <label for="name">Billing Information</label>
                            <input class="form-control" id="billing_name" type="text"
                                   placeholder="Customer Name"
                                   v-model="form.billing_name">
                            <input class="form-control mt-2" id="billing_address"
                                   type="text" placeholder="Address"
                                   v-model="form.billing_address">
                            <input class="form-control mt-2" id="billing_city" type="text"
                                   placeholder="City"
                                   v-model="form.billing_city">

                            <select class="form-select mt-2" id="billing_state" name="state" v-model="form.billing_state">
                              <option value="" selected disabled>State</option>
                              <option value="AL">Alabama</option>
                              <option value="AK">Alaska</option>
                              <option value="AZ">Arizona</option>
                              <option value="AR">Arkansas</option>
                              <option value="CA">California</option>
                              <option value="CO">Colorado</option>
                              <option value="CT">Connecticut</option>
                              <option value="DE">Delaware</option>
                              <option value="FL">Florida</option>
                              <option value="GA">Georgia</option>
                              <option value="HI">Hawaii</option>
                              <option value="ID">Idaho</option>
                              <option value="IL">Illinois</option>
                              <option value="IN">Indiana</option>
                              <option value="IA">Iowa</option>
                              <option value="KS">Kansas</option>
                              <option value="KY">Kentucky</option>
                              <option value="LA">Louisiana</option>
                              <option value="ME">Maine</option>
                              <option value="MD">Maryland</option>
                              <option value="MA">Massachusetts</option>
                              <option value="MI">Michigan</option>
                              <option value="MN">Minnesota</option>
                              <option value="MS">Mississippi</option>
                              <option value="MO">Missouri</option>
                              <option value="MT">Montana</option>
                              <option value="NE">Nebraska</option>
                              <option value="NV">Nevada</option>
                              <option value="NH">New Hampshire</option>
                              <option value="NJ">New Jersey</option>
                              <option value="NM">New Mexico</option>
                              <option value="NY">New York</option>
                              <option value="NC">North Carolina</option>
                              <option value="ND">North Dakota</option>
                              <option value="OH">Ohio</option>
                              <option value="OK">Oklahoma</option>
                              <option value="OR">Oregon</option>
                              <option value="PA">Pennsylvania</option>
                              <option value="RI">Rhode Island</option>
                              <option value="SC">South Carolina</option>
                              <option value="SD">South Dakota</option>
                              <option value="TN">Tennessee</option>
                              <option value="TX">Texas</option>
                              <option value="UT">Utah</option>
                              <option value="VT">Vermont</option>
                              <option value="VA">Virginia</option>
                              <option value="WA">Washington</option>
                              <option value="WV">West Virginia</option>
                              <option value="WI">Wisconsin</option>
                              <option value="WY">Wyoming</option>
                            </select>


                            <input class="form-control mt-2" id="billing_postal" type="text"
                                   placeholder="Postal Code"
                                   v-model="form.billing_postal">
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12">
                          <div class="form-group">
                            <label for="ccnumber">Credit Card Number</label>
                            <div>
                              <div class="tokenBox" id="tokenExIframeDiv" style="height: 75px;"></div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="form-group col-sm-4">
                          <label for="ccmonth">Month</label>
                          <select class="form-control" id="expmonth" v-model="form.expmonth">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                          </select>
                        </div>
                        <div class="form-group col-sm-4">
                          <label for="ccyear">Year</label>
                          <select class="form-control" id="expyear" v-model="form.expyear">
                            <option v-for="yr in yearRange" :value="yr" :key="yr">{{
                                yr
                              }}
                            </option>
                          </select>
                        </div>
                        <!--
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label for="cvv">CVV/CVC</label>
                            <input class="form-control" id="cvv" type="text" placeholder="123">
                          </div>
                        </div>
                        -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary" id="btnSubmit">Add</button>
          </div>
        </div>
      </div>
    </div>

    <div
        class="modal fade"
        id="editMethodModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="editMethodModalLabel"
        aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editMethodModalLabel">Edit Method</h5>
            <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="padding">
              <div class="row">
                <div class="form-group col-sm-4">
                  <label for="editexpmonth">Month</label>
                  <select
                      class="form-control"
                      id="editexpmonth"
                      v-model="editform.expmonth"
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                  </select>

                </div>
                <div class="form-group col-sm-4">
                  <label for="editccyear">Year</label>
                  <select
                      class="form-control"
                      id="editccyear"
                      v-model="editform.expyear"
                  >
                    <option
                        v-for="yr in yearRange"
                        :value="yr"
                        :key="yr"
                    >
                      {{ yr }}
                    </option>
                  </select>

                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
            >
              Close
            </button>
            <button type="button" class="btn btn-primary" id="btnEditSave" @click.prevent="updateCard">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {Modal} from "bootstrap";
import "jquery/dist/jquery.min.js";
import $ from "jquery";
import {useToast} from "vue-toastification";
import useVuelidate from "@vuelidate/core";

export default {
  name: "userSettings",
  props: ['signin'],
  setup() {
    // Get toast interface
    const toast = useToast();
    return {toast, v$: useVuelidate()};
  },
  data() {
    return {
      accountid:"",
      editform: {
        id: "",
        expyear: "",
        expmonth: "",
      },
      account:{
        email:''
      },
      form: {
        name: '',
        Acctnum: '',
        token: '',
        expyear: '',
        expmonth: '',
        billing_address: '',
        billing_city: '',
        billing_state: '',
        billing_postal: '',
        billing_name: '',
        rawdata: '',
        last_four: '',
        type: ''
      },
      accountDetails: {},
      payModal: "",
      editBillModal: "",
      loadOrderBtn: false,
      billBtnShown: false,
      iframe: '',
      isBtnLoaded: false,
      yearRange: [],
      search: '',
      options: {dom: '<"top"f>rt<"bottom"ilp><"clear">', buttons: ['csv']}
    }
  },
  computed: {
    ...mapGetters({
      'accountD': 'Accounts/getAccountDetails',
      'user': "userAccess/user",
      'discount': "Accounts/getDiscountData"
    }),
  },
  methods: {
    updateAccount(){
      this.$store.dispatch('Accounts/updateAccount', this.account).then(() => {
        //update localstorage
        let curUser = localStorage.getItem('user');
        curUser = JSON.parse(curUser);
        curUser.email = this.account.email
        localStorage.setItem('user', JSON.stringify(curUser))
        this.toast.success("Email Updated");
      });
    },
    discountDisp(type,num) {
      if (num !== undefined && num !== null && num !== '') {
        var dNum = this.discount[num]

        var discountMap = {'mat':{'1':0,'2':'20%','3':'27%','4':'29%'},
                            'chop':{'1':'10%','2':'15%','3':'20%','4':'30%','5':'40%','6':'25%','7':'5%'},
                            'glass':{'1':'Basic','2':'Plus','3':'Premium','4':'Max'},
                            'hardware':{'1':'Basic','2':'Plus','3':'Premium','4':'Max'},
                            'join':{'1':'10%','2':'15%','3':'20%','4':'30%','5':'40%','6':'25%','7':'5%'},
                            'length':{'1':'10%','2':'15%','3':'20%','4':'30%','5':'40%','6':'45%','7':'25%','8':'50%'},
                          }

        if(discountMap[type] !== undefined){
          dNum = discountMap[type][dNum];
        }

        return dNum
      }
    },
    updateDefault(id) {
      this.$store.dispatch('Accounts/updateMethodDefault', {id, default: true}).then(() => {
        //do a save popup
      });
    },
    clearForm() {
      const keys = Object.keys(this.form);
      // eslint-disable-next-line no-unused-vars
      keys.forEach((key, index) => {
        this.form[key] = '';
      });
    },
    expYearRange() {
      let lowEnd = new Date().getFullYear();
      let highEnd = new Date().getFullYear() + 10;
      while (lowEnd <= highEnd) {
        this.yearRange.push(lowEnd++);
      }
    },
    editMethod(billingid) {

      this.editform.id = ''
      this.editform.expmonth = ''
      this.editform.expyear = ''
      const foundObject = this.accountDetails.billing.find((obj) => obj.id === billingid);
      if (foundObject) {
        this.editform.id = billingid
        this.editform.expmonth = foundObject.expmonth
        this.editform.expyear = foundObject.expyear
      }
      //$("#editMethodModal").modal("show");
      this.editBillModal.show()
    },
    updateCard(){
      this.$store.dispatch("Accounts/updateMethod", this.editform).then(() => {
        this.$store.dispatch("Accounts/accountDetails", {
          Acctnum: this.accountid,
        }).then(res => {
          this.accountDetails = res
        });
        this.toast.success("Billing Method Updated!");
        this.editform.id = ''
        this.editform.expmonth = ''
        this.editform.expyear = ''
        this.editBillModal.hide()
      });
    },
    deleteMethod(billingid) {
      this.$store.dispatch('Accounts/deleteMethod', {billingid}).then(() => {
        this.toast.error("Billing Method Deleted!");
        if(this.signin) {
          this.$store.dispatch("Accounts/accountDetails", {
            Acctnum: this.accountid,
          }).then(res => {
            this.accountDetails = res
          });
        }
      });
    },
    addMethod() {
      //this.form.Acctnum = String(this.user.accounts[0].Acctnum);

      this.$store.dispatch('Accounts/addMethod', this.form).then(rez => {

        const billingFailure = rez.billing.paymentSuccess !== undefined && !rez.billing.paymentSuccess;
        const legacyFailure = rez.success !== undefined && !rez.success;

        if (billingFailure) {
          this.toast.error("There was an issue with the card " + (rez.billing.message || ""));
        } else if (legacyFailure) {
          //this.toast.error("There was an issue with the card");
          if (rez.message !== undefined) {
            this.toast.error(rez.message);
          } else {
            for (let key in rez.data) {
              this.toast.error("Required " + rez.data[key]);
            }
          }
        } else {
          this.iframe.reset()
          this.payModal.hide();
          if(this.signin) {
            this.$store.dispatch("Accounts/accountDetails", {
              Acctnum: this.accountid,
            }).then(res => {
              this.accountDetails = res
            });
          }
        }

        /*
        if (!rez.success) {
          if (rez.msg !== undefined) {
            this.toast.error(rez.msg);
          } else {
            for (let key in rez.data) {
              this.toast.error("Required " + rez.data[key]);
            }
          }
        } else {

          this.iframe.reset()
          this.payModal.hide();
          if(this.signin) {
            this.$store.dispatch("Accounts/accountDetails", {
              Acctnum: this.accountid,
            }).then(res => {
              this.accountDetails = res
            });
          }
        }
        */
      });

    }
  },
  mounted() {

    let user = JSON.parse(localStorage.getItem('vuser'))
    let Acctnum = 0
    //console.log('usr',user, this.user.accounts[0].Acctnum)
    if (user !== undefined && user !== null) {
      Acctnum = user.accounts[0].Acctnum
    } else {
      Acctnum = this.user.accounts[0].Acctnum
    }
    this.accountid = Acctnum
    this.account.email = this.user?.email
    this.editBillModal = new Modal(document.getElementById("editMethodModal"));

    this.expYearRange()
    let self = this;

    this.$store.dispatch('Accounts/accountDetails', {Acctnum}).then(() => {
      this.accountDetails = this.accountD
    });
    if (!this.signin) {
      this.$store.dispatch('Accounts/discountNames');
    }

    const payM = document.getElementById('methodModal')
    this.payModal = new Modal(document.getElementById("methodModal"));
    payM.addEventListener('shown.bs.modal', async function () {
      //let Acctnum = Acctnum
      await self.$store.dispatch("Accounts/accountDetails", {
        Acctnum: Acctnum,
      });
      await self.clearForm();
      self.form.Acctnum = Acctnum.toString();
      if (!self.isBtnLoaded) {
        console.log("🤬load  here");
        let iframeConfig = await self.$store.dispatch(
            "Accounts/getTokenXCreds"
        );
        console.log("conf", iframeConfig);
        // eslint-disable-next-line no-undef
        self.iframe = new TokenEx.Iframe("tokenExIframeDiv", iframeConfig);

        self.iframe.on("load", function () {
          console.log("iFrame Loaded");
        });
        self.iframe.on("focus", function () {
          console.log("iFrame Focus");
        });
        self.iframe.on("blur", function () {
          console.log("iFrame blur");
          self.iframe.validate();
        });
        self.iframe.on("validate", function (data) {
          if (!data.isValid) {
            self.errorMsg = true;
            self.msg = "Check Credit Card Number";
          } else {
            self.errorMsg = false;
            self.msg = "";
          }
          console.log("iFrame validate:" + JSON.stringify(data));
        });
        self.iframe.on("cardTypeChange", function (data) {
          console.log("iFrame cardTypeChange:" + JSON.stringify(data));
        });
        self.iframe.on("tokenize", async function (data) {
          console.log("iFrame Tokenize:", data);
          self.form.rawdata = JSON.stringify(data);
          self.form.token = data.token;
          self.form.last_four = data.lastFour;
          self.form.type = data.cardType;

          self.addMethod();
        });
        self.iframe.on("error", function (data) {
          self.errorMsg = true;
          self.msg = "Unknown error please refresh page";
          console.log("iFrame error:" + JSON.stringify(data));
        });

        $('#btnSubmit').off().on('click', function (event) {
          event.preventDefault();
          self.iframe.tokenize();
        });

        self.isBtnLoaded = true;

        self.iframe.load();
      }
    });

  }
}
</script>

<style scoped>
.card:hover {
  overflow: hidden;
  transform: none;
}
</style>