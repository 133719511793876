<template>
  <div>
    <!---MAIN IMAGE START-->
    <div class="main_img_banner">
      <div class="hero mainImg">
      </div>
    </div>

    <div class="container-fluid mb-3 p-4 position-relative" style="background-color: #1A1D20; min-height: 248px;">
      <h5 class="p-3 position-absolute fw-bold text-center" style="background: #FAE565; top: -30px; left: 50%; transform: translateX(-50%); ">
        Popular Collections</h5>
      <div class="product-menu mt-4">
        <Carousel :items-to-show="7" :wrap-around="false">
          <Slide v-for="(item, key) in poplularCollections" :key="key">
            <div :class="key !== 'spot_7' ? 'product_box_item' : 'value_box_item'">
              <a href="" @click.prevent="goTo({name: 'shop', query:{collection: item.collections ?? [item.value]}})">
                <span :style="key !== 'spot_7' ? '' : 'white-space: pre-line;'">{{ item.name }}</span>
                <img v-if="key !== 'spot_7'" :src="getPopImg(item)" @error="imageUrlAlt">
              </a>
            </div>
          </Slide>
        </Carousel>
      </div>
    </div>

    <div class="container-fluid mb-3 p-4 position-relative" style="background-color: #1A1D20; min-height: 275px;">
      <h4 class="text-light text-center"> Explore All Our Collections</h4>
      <div class="product-menu mt-4">
        <Carousel :items-to-show="6.5" :transition="1000" :items-to-scroll="6" :wrap-around="false">
          <Slide v-for="item in collections" :key="item.id">
            <div class="product_box_item">
              <a href="" @click.prevent="goTo({name: 'shop', query:{collection: item.collections ?? [item.id]}})">
                <span>{{ item.name }}</span>
                <img :src="getPopImg(item)" @error="imageUrlAlt">
              </a>
            </div>
          </Slide>
          <template #addons>
            <Navigation/>
          </template>
        </Carousel>
      </div>
    </div>


    <!---WHAT'S NEW-->
    <div class="container-fluid mb-3">
      <div class="container pt-4 pb-4 ">
        <h3 class="text-center fw-bold mb-5 mt-5">What's New</h3>
        <div class="row row-cols-1 row-cols-md-3 g-4 justify-contents-center section-whatsnew">
          <div class="col mb-3" v-for="item in whatsNew" :key="item.value">
            <div class="card card-new h-100 text-center shadow-sm mx-auto" @click.prevent="goTo({name: 'shop', query:{collection: [parseInt(item.value)]}})">
              <div class="card-img">
                <img :src="getPopImg(item)" class="card-img-top" alt="...">
              </div>
              <div class="card-body bg-yellow">
                <h5 class="card-title fw-bold pt-3">{{ item.name }}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid mb-3">
      <div class="container pt-4 pb-4 ">
        <RecentPurchase></RecentPurchase>
      </div>
    </div>

    <!--INSTAGRAM-->
    <div class="container-fluid bg-dark p-4">
      <div class="container pt-4 pb-4">
        <!-- <h3 style="color: white;" class="fw-bold mt-4 mb-5">Follow us on Instagram</h3> -->
        <div class="row row-cols-1 row-cols-md-3 g-4">
          <div class="col mb-3">
            <div class="card card-insta h-100">
              <div class="col mb-3 px-5 pt-4">
                <h3 class="fw-bold mb-3">Our Mission</h3>
                <p class="lh-base fw-bold">We are dedicated to exceeding customer expectations and delivering an
                  exceptional experience. </p>
                <p class="lh-base">Our mission is to be a trusted partner and support the custom framing industry with
                  integrity. All our beautiful mouldings are imported exclusively by AMPF directly from the finest
                  European and Asian craftsmen.</p>

              </div>
            </div>

          </div>
          <div class="col mb-3">

            <div class="card card-insta h-100">
              <div class="card-body bg-yellow py-4 px-5 text-dark">
                <h3 class="fw-bold">Explore Our Value Line Collections</h3>

                <button class="btn btn-dark mt-3 me-3" type="button" @click.prevent="goTo({name: 'shop', query:{collection: [133]}})">
                  Ferrara
                </button>
                <button class="btn btn-dark mt-3 me-3" type="button" @click.prevent="goTo({name: 'shop', query:{collection: [159]}})">
                  Innsbruck
                </button>
                <button class="btn btn-dark mt-3 me-3" type="button" @click.prevent="goTo({name: 'shop', query:{collection: [132]}})">
                  Modena
                </button>
                <!-- <button class="btn btn-dark mt-3 me-3" type="button" @click.prevent="goTo({name: 'shop', query:{collection: [133]}})">Strainer Bar</button> -->
                <!-- ??????-->
                <button class="btn btn-dark mt-3 me-3" type="button" @click.prevent="goTo({name: 'shop', query:{collection: [87]}})">
                  Value Line
                </button>

                <p class="mt-4 mb-3">Commercial Projects, Artist Friendly, Everyday Low Prices. <b>No box quantities
                  needed!</b></p>
              </div>
            </div>

          </div>
          <div class="col mb-3">

            <div class="card card-insta h-100 px-5 text-center">
              <h3 class="fw-bold mb-3 pt-3">Like & Follow Us</h3>
              <div class="row">
                <div class="col-6">
                <span class="sm-link mt-4 mb-3 d-block">
                   <a href="https://www.facebook.com/people/AMPF-Moulding/100092358672420/?mibextid=LQQJ4d" target="_blank" class="link-offset-2 text-light fs-3 fw-bold" style="text-decoration: none;"><img width="75" src="@/assets/images/ic_facebook.svg" class="me-3"></a>
                </span>
                </div>
                <div class="col-6">
                  <span class="sm-link mt-4 mb-4 d-block">
                     <a href="https://www.instagram.com/ampfframes/" target="_blank" class="link-offset-2 text-light fs-3 fw-bold" style="text-decoration: none;"><img width="75" src="@/assets/images/ic_instagram.svg" class="me-3"></a>
                  </span>
                </div>
              </div>
              <p>Visit our Social Media profiles for special announcements and projects!</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!---LOCATIONS-->
    <div class="container-fluid">
      <div class="container pt-4 pb-4">
        <h3 class="mb-3 fw-bold mt-4 mb-5">Locations</h3>
        <div class="row align-items-end mb-3">
          <div class="col mb-3 pe-5">
            <h5 class="fw-bold">New Orleans</h5>
            <p class="mb-0">4751 Paris Rd.</p>
            <p class="mb-0">Chalmette, LA 70043</p>
          </div>
          <div class="col mb-3 pe-5">
            <h5 class="fw-bold">Greensboro</h5>
            <p class="mb-0">7901 Industrial Village Rd.</p>
            <p class="mb-0">Greensboro, NC 27409</p>
          </div>

          <div class="col mb-3 pe-5">
            <h5 class="fw-bold">Tampa</h5>
            <p class="mb-0">612 S. Ware Blvd.</p>
            <p class="mb-0">Tampa, FL 33619</p>
          </div>
          <div class="col mb-3">
            <h5 class="fw-bold">Denver</h5>
            <p class="mb-0">13750 E. Smith Dr.</p>
            <p class="mb-0">Aurora, CO 80011</p>
          </div>
        </div>

        <div class="mt-5 pt-4 pb-4">
          <img src="@/assets/images/banner-30years.png" class="img-fluid">
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import RecentPurchase from "@/components/Admin/RecentPurchase.vue";

import {Carousel, Navigation, Slide} from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'

export default {
  name: 'HomeView',
  components: {
    RecentPurchase,
    Carousel,
    Slide,
    Navigation
  },
  data() {
    return {
      loader: "dots",
      defaultImg: require("@/assets/images/img-default.png"),
      valueBox: {},
      collections: [],
      poplularCollections: [],
    };
  },
  computed: {
    ...mapGetters({
      poplularCollection: "product/getPopularCollection",
      whatsNew: "product/getWhatsNew",
    }),
  },
  methods: {
    imageUrlAlt(event) {
      event.target.src = this.defaultImg;
    },
    goTo(rl) {
      this.$router.push(rl);
    },
    getPopImg(item) {
      let randomNumber = Math.floor(Math.random() * 1000) + 1;
      return process.env.VUE_APP_CDN + '/collections/' + (item.value ?? item.id) + '.jpg?ver=' + randomNumber
    }
  },
  mounted() {
    this.$store.dispatch('product/settingsData').then(rez => {
      let valuelines = rez.find(obj => obj.name === "value_lines");
      if (valuelines.data !== undefined) {
        let valuelinesObj = JSON.parse(valuelines.data)
        this.valueBox = valuelinesObj
        this.poplularCollections = this.poplularCollection
        this.poplularCollections.spot_7 = valuelinesObj
      }
    })

    this.$store.dispatch('product/collections', {perPage: 1000, orderCol: 'name', orderDir: 'ASC'}).then(rez => {
      this.collections = rez.data
    })
  }
}
</script>

<style scoped>

.mainImg {
  background-image: url('@/assets/images/main_herobg.jpg');
  transform: translateX(0%);
  opacity: 1;
}

</style>
