import axios from 'axios'

export default {
    namespaced: true,
    state: {
        orderData: {},
        orderDetails:{},
        invoiceDetails:{},
        invoiceDetailsTotals:{},
        paymentBatchData:{}
    },
    getters: {
        getPaymentBatchData(state){
            return state.paymentBatchData
        },
        getOrders(state){
            return state.orderData
        },
        getOrderDetails(state){
            return state.orderDetails
        },
        getInvoiceDetails(state){
            return state.invoiceDetails
        },
        getInvoiceDetailsTotals(state){
            return state.invoiceDetailsTotals
        },
    },
    actions: {
        getAdminOrderData({commit},{options}){
            return new Promise((resolve, reject) => {
                let data = { ...options }
                let config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/api/accountOrders',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                }

                axios(config)
                    .then(response => {
                        commit('setOrderData',response.data.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        getOrderData({commit},{options}){
            return new Promise((resolve, reject) => {
                let data = { ...options }
                var config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/orders/listOrders',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                }

                axios(config)
                    .then(response => {
                        commit('setOrderData',response.data.data.orders)
                        resolve(response.data.orders)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },

        orderDetails({commit},orderid){
            return new Promise((resolve, reject) => {
                let data = { orderid }
                var config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/orders/listOrderDetails',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                }

                axios(config)
                    .then(response => {
                        commit('setOrderDetails',response.data.data.orders)
                        resolve(response.data.orders)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        makePayment(_,Invnum){
            return new Promise((resolve, reject) => {
                let data = { ...Invnum }
                let config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/api/admin/makePayment',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                }

                //return;
                axios(config)
                    .then(response => {
                        //commit('setInvoiceDetails',response.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        makeBatchPayment(_,InvoiceIds){
            return new Promise((resolve, reject) => {
                let data = { InvoiceIds }
                var config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/api/makeBatchPayments',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                }

                axios(config)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        paymentBatchData({commit},{options}){
            return new Promise((resolve, reject) => {
                let data = { ...options }
                var config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/api/searchPaymentBatch',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                }

                axios(config)
                    .then(response => {
                        commit('setPaymentBatchData',response.data.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        getAdminInvoiceDetails({commit},Invoicenum){
            return new Promise((resolve, reject) => {
                let data = { ...Invoicenum }
                let config = {
                    method: 'get',
                    url: process.env.VUE_APP_API_SERVER + '/api/admin/invoices',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    params: data
                }
                axios(config)
                    .then(response => {
                        commit('setInvoiceDetails',response.data.data.data[0])
                        commit('setInvoiceDetailsTotals',response.data.invdata)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        getAdminOrderDetails({commit},{Ordernum}){
            return new Promise((resolve, reject) => {
                let data = { Ordernum }
                let config = {
                    method: 'get',
                    url: process.env.VUE_APP_API_SERVER + '/api/admin/ordersDetails',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    params: data
                }

                axios(config)
                    .then(response => {
                        commit('setOrderDetails',response.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        getAccountCredits(){
            return new Promise((resolve, reject) => {
                let data = {  }
                let config = {
                    method: 'get',
                    url: process.env.VUE_APP_API_SERVER + '/api/accountCredits',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    params: data
                }

                axios(config)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
    },
    mutations: {
        setPaymentBatchData(state, data){
            state.paymentBatchData = !data ? {} : data
        },
        setOrderData(state, orderData){
            state.orderData = !orderData ? {} : orderData
        },
        setOrderDetails(state, orderData){
            state.orderDetails = !orderData ? {} : orderData
        },
        setInvoiceDetails(state, invoiceData){
            state.invoiceDetails = !invoiceData ? {} : invoiceData
        },
        setInvoiceDetailsTotals(state, invoiceData){
            state.invoiceDetailsTotals = !invoiceData ? {} : invoiceData
        },
    }
}