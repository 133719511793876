<template>
  <div class="container">

    <div class="row row-cols-1 row-cols-xl-3 g-4 product-search card-group">
      <div class="col col-lg-4 col-xl-3" v-for="item in recentPurchase" :key="item">
        <div class="card card-products shadow-md">
          <div class="card-img">
            <img
                :src="imageUrl(item)"
                @error="imageUrlAlt"
                class="card-img-top img-fluid"
                alt="..."
            />
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col text-start">
                <span class="text-uppercase subdued d-block overflow-x-hidden text-nowrap text-truncate codeFS" >#{{ item.Code }}</span>
              </div>
              <div class="col text-end">
                <span class="d-block fw-bold" v-if="item.FrameType === 'MOULDING'" v-html="widthFrac(item.Width)"></span>
              </div>
            </div>
            <div class="row">
              <div class="col text-start" >
                    <span class="d-block fs-5 text overflow-x-hidden text-nowrap text-truncate dispH" :title="item.display_name">
                      {{item.display_name }}
                    </span>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col text-start">
                <a href="#" class="btn btn-dark rounded-0 d-block" @click.prevent="goToShop(item.Code)">Shop</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Fraction from "fraction.js";
export default {
  name: "FrequentlyPurchased",
  data() {
    return {
      defaultImg: require("@/assets/images/img-default.png"),
    };
  },
  computed: {
    ...mapGetters({
      recentPurchase: "Accounts/getRecentPurchase",
    }),
  },
  methods: {
    goToShop(Code) {
      window.location.href = '/#/Shop/' + Code + '/Y';
    },
    imageUrlAlt(event) {
      event.target.src = this.defaultImg;
    },
    imageUrl(item) {
      let loc = process.env.VUE_APP_CDN + "/frames/" + item.Code + "/" + item.Code + "-small.jpg";
      if (item.FrameType === 'MATS') {
        loc = process.env.VUE_APP_CDN + "/mat/" + item.ManufCode + "/" + item.ManufCode + "-small.jpg";
      }
      return loc;
    },
    widthFrac(decWidth) {
      let f = new Fraction(decWidth);
      let res = f.toFraction(true);
      let resx = res.split(" ");

      let den = "";
      if (resx[1] !== undefined) {
        den = resx[1];
      }

      return resx[0] + ' ' + den;
    },
  },
  mounted() {

    this.$store.dispatch('Accounts/recentPurchase',{'nodate': 1, 'perPage':100}).then(async rez => {
      if(rez.length > 0) {
        await this.$store.dispatch('Accounts/actionSetRecentPurchase', rez)
      }
    });
  }
}
</script>



<style scoped>
.dispH {height:30px;}
.codeFS {font-size:2.8vh;}

.card.card-products .card-img {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 280px;
}


.card-products .card-img {
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}

.card-products img {
  width: 100%;
  max-height: 190px;
}

</style>