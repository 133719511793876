import axios from 'axios'

export default {
    namespaced: true,
    state: {
        productData: [],
        productDetails: {},
        productLocationQty: {},
        whereToBuyData: {},
        wtbCenter:'',
        searchFilters: {},
        topSearchData:[],
        settingsData:{},
        initialState:{
            defaultImg: require("@/assets/images/img-default.png"),
            Code: '',
            finish: '',
            width: '',
            rabbet: '',
            styles: '',
            hasDiscount: false,
            discountObj: {},
            orglengthPriceF: 0,
            hInt: 0,
            wInt: 0,
            lengthPriceF: 0,
            chopPriceF: 0,
            orgchopPriceF: 0,
            orgjoinPriceF: 0,
            joinPriceF: 0,
            exactPriceF: 0,
            orgexactPriceF: 0,
            orghardwarePriceF: 0,
            hardwarePriceF: 0,
            orgglassPriceF: 0,
            glassPriceF: 0,
            matPriceF: 0,
            orgmatPriceF: 0,
            orgreadyPriceF: 0,
            readyPriceF: 0,
            boxqty: 0,
            joinfee: 0,
            joinosfee: 0,
            yourLength: '',
            yourLengthV: 0,
            customLength: '',
            productWidth: 0,
            isInStock: 0,
            backremq: 0,
            backcancel: 0,
            backallq: 0,
            backtype: '',
            ShipVal: 0,
            onhand: 0,
            BackOrderVal: '',
            CancelledVal: '',
            ShipOrder: 0,
            maxins: 0,
            display_name: '',
            locations: {
                'B': {'name': 'Greensboro'},
                'C': {'name': 'New Orleans'},
                'E': {'name': 'Tampa'},
                'G': {'name': 'Denver'},
            },
            productType: 0,
            locationQty: {
                GName: '',
                GOnhand: 0,
                BName: '',
                BOnhand: 0,
                CName: '',
                COnhand: 0,
                EName: '',
                EOnhand: 0,
                acctLoc1: '',
                acctLoc1Name: '',
                defaultonhand1Raw: 0,
                defaultonhand1: 0
            },
            productSelection: {
                type: 'length',
                instructions: '',
                length: 0,
                quantity: 0,
                location: '',
                dimension: {
                    length: 0,
                    length_fraction: 0,
                    width: 0,
                    width_fraction: 0,
                    unitedInches: 0
                }
            }
        }
    },
    getters: {
        getSettingsData(state){
            return state.settingsData
        },
        getPopularCollection(state){
            let collection = {};
            if(Object.keys(state.settingsData).length !== 0) {
                const popularCollections = state.settingsData.find(obj => obj.name === "popular_collections");

                if (popularCollections.data !== undefined) {
                    let popcolObj = JSON.parse(popularCollections.data)
                    let ct = 0
                    for (let key in popcolObj) {
                        if (Object.prototype.hasOwnProperty.call(popcolObj, key) && ct <= 5) {
                            const value = popcolObj[key];
                            //console.log(`Key: ${key}, Value: ${value}`);
                            collection[key] = value;
                            //document.getElementById(key).value = value;
                            ct++
                        }
                    }
                }
            }
            return collection
        },
        getWhatsNew(state){
            let collection = {};
            if(Object.keys(state.settingsData).length !== 0) {
                const popularCollections = state.settingsData.find(obj => obj.name === "popular_collections");

                if (popularCollections.data !== undefined) {
                    let popcolObj = JSON.parse(popularCollections.data)
                    let ct = 0
                    for (let key in popcolObj) {
                        if (Object.prototype.hasOwnProperty.call(popcolObj, key) && ct >= 6) {
                            const value = popcolObj[key];
                            collection[key] = value;
                            //document.getElementById(key).value = value;
                        }
                        ct++
                    }
                }
            }
            return collection
        },
        getFilterData(state){
            return state.searchFilters
        },
        getWTBData(state){
            return state.whereToBuyData
        },
        getWTBCenter(state){
            return state.wtbCenter
        },
        getProducts(state){
            return state.productData
        },
        getSearchTopData(state){
            return state.topSearchData
        },
        getProductDetail(state){
            return state.productDetails
        },
        getProductLocationQty(state){
            return state.productLocationQty
        },
        getInitialState(state){
            return state.initialState
        }
    },
    actions: {
        getWTBData({commit},{options}){
            return new Promise((resolve, reject) => {
                let data = { ...options }
                let config = {
                    method: 'get',
                    url: process.env.VUE_APP_API_SERVER + '/api/WhereToBuy',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    params: data
                }

                axios(config)
                    .then(response => {
                        commit('setWhereToBuyData', response.data.locations)
                        commit('setWhereToBuyCenter', response.data.center)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        getFilters({commit},{options}){
            return new Promise((resolve, reject) => {
                let data = { ...options }
                let method = 'Shop/ProductFilters'
                if(this.getters['userAccess/accessToken'] !== ''){
                    method = 'ProductFilters'
                }
                let config = {
                    method: 'get',
                    url: process.env.VUE_APP_API_SERVER + '/api/'+method,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    params: data
                }
                axios(config)
                    .then(response => {
                        commit('setFilterData', response.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        getProductData({commit},{options, clear}){
            return new Promise((resolve, reject) => {
                let data = { ...options }
                let method = 'Shop/Products'
                if(this.getters['userAccess/accessToken'] !== ''){
                    method = 'Products'
                }

                let config = {
                    method: 'get',
                    url: process.env.VUE_APP_API_SERVER + '/api/'+method,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    params: data
                }

                axios(config)
                    .then(response => {
                        if(clear){
                            commit('setProductData', response.data.data)
                        }else {
                            commit('addProductData', response.data.data)
                        }
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        searchTopData({commit},{options}){
            return new Promise((resolve, reject) => {
                let method = 'Shop/Products'
                if(this.getters['userAccess/accessToken'] !== ''){
                    method = 'Products'
                }

                let data = { ...options }
                let config = {
                    method: 'get',
                    url: process.env.VUE_APP_API_SERVER + '/api/'+method,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    params: data
                }

                axios(config)
                    .then(response => {
                        commit('setTopSearchData', response.data.data)
                        resolve(response.data.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        getProductDetails({commit},Code){
            return new Promise((resolve, reject) => {
                let data = { Code }
                let headers = {}
                if(this.getters['userAccess/isLoggedin']){
                    headers = {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    }
                }

                let config = {
                    method: 'get',
                    url: process.env.VUE_APP_API_SERVER + '/api/getProductDetailsByCode',
                    headers: headers,
                    params: data
                }
                axios(config)
                    .then(response => {
                        commit('setProductDetails',response.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        getProductLocationQty({commit}, Code){
            return new Promise((resolve, reject) => {
                let data = { Code }
                let headers = {}
                if(this.getters['userAccess/isLoggedin']){
                    headers = {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    }
                }else{
                    reject({'msg': 'not logged in'})
                }

                let config = {
                    method: 'get',
                    url: process.env.VUE_APP_API_SERVER + '/api/getLocationQty',
                    headers: headers,
                    params: data
                }

                axios(config)
                    .then(response => {
                        commit('setProductLocationQty',response.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        settingsData({commit}){
            return new Promise((resolve, reject) => {
                let data = {}
                let config = {
                    method: 'get',
                    url: process.env.VUE_APP_API_SERVER + '/api/settings',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    params: data
                }

                axios(config)
                    .then(response => {
                        commit('setSettingsData', response.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        collections(_,data){
            return new Promise((resolve, reject) => {

                let config = {
                    method: 'get',
                    url: process.env.VUE_APP_API_SERVER + '/api/Shop/Collections',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    params: data
                }

                axios(config)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
    },
    mutations: {
        setSettingsData(state, data){
            state.settingsData = data
        },
        setFilterData(state, filters){
            state.searchFilters = filters
        },
        setWhereToBuyCenter(state, whereToBuyCenter){
            state.wtbCenter = whereToBuyCenter
        },
        setWhereToBuyData(state, whereToBuyData){
            state.whereToBuyData = whereToBuyData
        },
        setTopSearchData(state, productData){
            state.topSearchData = productData
        },
        setProductData(state, productData){
            state.productData = productData
        },
        addProductData(state, productData){
            state.productData = [...state.productData,...productData]
        },
        setProductDetails(state, productDetails){
            state.productDetails = productDetails
        },
        setProductLocationQty(state, ploc){
            state.productLocationQty = ploc
        },
    }
}