<template>
  <div class="container">
    <h1 class="mt-3">Update Information Form</h1>

    <div class="card mt-3 mb-3">
      <div class="card-body">
        <p>Welcome to AMPF’s New Website!  Our advanced layout gives you access to our premium mouldings, products, inventory, and pricing. You can now Review, Manage, and Maintain your account here. Please take a moment to help us update and safely secure your information. Thank you to our returning customers, for those who are new to AMPF, we extend a warm welcome.</p>
      </div>
    </div>

    <form @submit.prevent="submitForm" autocomplete="off">
      <div class="card collapsed-card">
        <div class="card-header">
          <h4 class="card-title">Update email and password</h4>
        </div>
        <div class="card-body p-3">
          <div class="row mb-3">
            <div class="col-12">
              <label for="email" class="form-label">Email address</label>
              <input type="email" class="form-control" id="email" v-model="form.email" aria-describedby="emailHelp" placeholder="Enter email" v-on:keydown.enter.prevent="disableEnterKey" required>
              <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>
            </div>
          </div>
          <!--
          <div class="mb-3">
            <label for="address" class="form-label">Address</label>
            <input type="text" class="form-control" id="address" placeholder="Enter your address">
          </div>
          <div class="row mb-3">
            <div class="col-md-4">
              <label for="city" class="form-label">City</label>
              <input type="text" class="form-control" id="city" placeholder="Enter your city">
            </div>
            <div class="col-md-4">
              <label for="state" class="form-label">State</label>
              <select class="form-select" name="state">
                <option value="" selected disabled>Select a state</option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>

            </div>
            <div class="col-md-4">
              <label for="postalCode" class="form-label">Postal Code</label>
              <input type="text" class="form-control" id="postalCode" placeholder="Enter your postal code">
            </div>
          </div>
    -->

          <div class="row mb-3">
            <div class="col-12">
              <label for="password" class="form-label">Password</label>
              <input type="password" class="form-control" id="password" v-model="form.password" placeholder="Password" v-on:keydown.enter.prevent="disableEnterKey">
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <label for="confirmPassword" class="form-label">Confirm Password</label>
              <input type="password" class="form-control" id="confirmPassword" v-model="confirmPass" placeholder="Confirm Password" v-on:keydown.enter.prevent="disableEnterKey">
            </div>
          </div>

        </div>
      </div>

      <!--
      <div class="card collapsed-card mt-5 mb-5">
        <div class="card-header">
          <h4 class="card-title">Please update your billing information.</h4>
        </div>
        <div class="card-body p-3">

          <div class="row mb-3">
            <div class="col-12">
              <user-settings :signin="true"></user-settings>
            </div>
          </div>
        </div>
      </div>
      -->

      <div class="row mb-3 text-center">
        <div class="col-12">
          <button type="submit" class="btn btn-primary mb-5">Update and Login</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>

import useVuelidate from '@vuelidate/core'
import {useToast} from "vue-toastification";
import {mapGetters} from "vuex";
//import userSettings from "@/components/Admin/userSettings.vue";


export default {
  name: 'verifyData',
  setup() {
    const toast = useToast();
    return {toast, v$: useVuelidate()};
  },
  data() {
    return {
      loading: false,
      confirmPass: "",
      fullPage: true,
      canCancel: false,
      useSlot: false,
      noData: true,
      loader: "dots",
      timeout: 10000, //ms
      color: "#00ab00",
      bgColor: "#4b4b4b",
      form: {
        email: null,
        password: "",
      }
    }
  },
  computed: {
    ...mapGetters({
      'UserInfo': 'userAccess/user',
    }),
  },
  methods: {
    disableEnterKey(event) {
      event.preventDefault();
    },
    async submitForm() {
      console.log('submit form')
      const result = await this.v$.$validate()

      if (!result) {
        console.log('submit form 1')
        this.toast.error('Passwords must match');
        return
      }

      if (this.confirmPass !== this.form.password) {
        console.log('submit form 2')
        this.toast.error('Passwords must match');
        return;
      }

      let loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: this.canCancel,
        color: this.color,
        backgroundColor: this.bgColor,
        height: this.height,
        width: this.width,
        loader: this.loader,
        opacity: 0.3,
      });

      this.$store
          .dispatch("user/updateUserInfo", this.form)
          .then((rez) => {
            loader.hide();
            if (!rez.success) {
              Object.entries(rez.data).forEach(([field, errors]) => {
                this.toast.error(field + ' ' + errors);
              });
            } else if (rez.success) {
              let user = JSON.parse(localStorage.getItem('vuser'))
              this.$store.dispatch('userAccess/login2', {username: user.username, password: this.form.password})
                  .then(async () => {
                    if (this.$store.getters['userAccess/invalidLogin'] === true) {
                      console.log('invalid')
                      this.invalidLogin = true
                    } else {
                      await this.$store.dispatch('userAccess/isAuthenticated');
                      location.href = '/';
                    }
                  })
                  .catch(error => {
                    //this.loading = false
                    console.log('error🤷‍️' + error.message)
                  })
            }
          });
    },
  }, mounted() {

    const passwordInput = document.getElementById("password");

    passwordInput.addEventListener("input", function() {
      if (passwordInput.value.trim() !== "") {
        passwordInput.classList.add("bigPassword");
      } else {
        passwordInput.classList.remove("bigPassword");
      }
    });

    const confirmPasswordInput = document.getElementById("confirmPassword");

    confirmPasswordInput.addEventListener("input", function() {
      if (confirmPasswordInput.value.trim() !== "") {
        confirmPasswordInput.classList.add("bigPassword");
      } else {
        confirmPasswordInput.classList.remove("bigPassword");
      }
    });


  }
}

</script>

<style scoped>
.card:hover {
  overflow: hidden;
  transform: none;
}

.bigPassword {
  font-size:35px !important;
  height: 38px;
}
</style>