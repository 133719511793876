<template>
  <div class="login-form">
    <p class="bg-success white" v-show="msg">
      {{ msg }}
    </p>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="submitForm" autocomplete="off">
          <p class="alert alert-danger" role="alert" v-show="showemailverify">Please verify your email before logging
            in</p>
          <p class="alert alert-danger" role="alert" v-show="v$.form.username.$error">Username is invalid or empty</p>
          <p class="alert alert-danger" role="alert" v-show="v$.form.password.$error">Password is empty</p>
          <div class="form-group">
            <input type="text" v-model="form.username"  id="username" class="form-control"
                   placeholder="Username (account #)">
            <i class="fa fa-user"></i>
          </div>
          <div class="form-group log-status">
            <input type="password" class="form-control"  v-model="form.password"
                   id="password"
                   placeholder="Password">
            <!--<i class="toggle-password fa fa-eye" id="togglePassword"></i>-->
            <font-awesome-icon class="toggle-password" :icon="tglpass" id="togglePassword"/>
          </div>
          <p class="alert alert-danger" role="alert" v-show="invalidLogin">Your username and password did not match.
            Please
            try again.</p>
          <button type="submit" class="btn btn-secondary" color="success" :loading="loading" :disabled="loading"
                  @click="loader(true)">
            <span class="spinner-border spinner-border-sm d-none" role="status" aria-hidden="true"></span>
            Log In
          </button>
          <div class="login-links">
            <router-link class="link" to="/forgot_password">Forgot your password?</router-link>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {required} from '@vuelidate/validators'
import {mapGetters} from "vuex";


export default {
  name: 'Login-page',
  props: ['extoken'],
  setup: () => ({v$: useVuelidate()}),
  data() {
    return {
      loading: false,
      tglpass: 'eye',
      msg: '',
      invalidLogin: false,
      showemailverify: false,
      form: {
        username: null,
        password: null
      }
    }
  },
  validations() {
    return {
      form: {
        username: {required},
        password: {required}
      }
    }
  },
  computed: {
    ...mapGetters({
      'UserInfo': 'userAccess/user',
    }),
  },
  methods: {
    async submitForm() {
      const result = await this.v$.$validate()
      if (!result) {
        this.loading = false
        return
      }
      // if (this.form.username && this.form.password) {
      this.$store.dispatch('userAccess/login2', {username: this.form.username, password: this.form.password})
          .then(async res => {
            this.loading = false
            document.querySelector('.spinner-border').classList.add('d-none');

            if (res.firsttime !== undefined && res.firsttime) {
              //todo clear all localstorage tokens

              localStorage.setItem('vtoken', res.vtoken)
              localStorage.setItem('vuser', JSON.stringify(res.user))
              this.$router.push({name: 'verifyData'})
            } else if (this.$store.getters['userAccess/invalidLogin'] == true) {
              this.invalidLogin = true
              this.loading = false
            } else {
              await this.$store.dispatch('userAccess/isAuthenticated');
              location.href = '/';
            }
          })
          .catch(() => {
            this.loading = false
            // alert('🤷‍️' + error.message)
          })
      // }
    },
    successRedirect() {
      this.loading = false
      //const redirectTo = this.$route.query.redirectTo || {name: 'dashboard'}
      const redirectTo = {name: 'home'}
      this.$router.push(redirectTo)
    },
    loader(lv) {
      document.querySelector('.spinner-border').classList.remove('d-none');
      this.loading = lv
      this.submitForm();
    }
  }, mounted() {
    if (this.$route.params.msg !== undefined) {
      this.msg = this.$route.params.msg
    }
    if (this.extoken !== undefined && this.extoken !== '') {
      this.$store.dispatch('userAccess/ssoLogin', {token: this.extoken}).then(async () => {
        await this.$store.dispatch('userAccess/isAuthenticated');
        location.href = '/';
      });
    }

    const passwordInput = document.getElementById("password");
    const togglePasswordButton = document.getElementById("togglePassword");
    let self = this
    togglePasswordButton.addEventListener("click", function () {
      if (passwordInput.type === "password") {
        passwordInput.type = "text";
        self.tglpass = 'eye-slash';
        passwordInput.classList.remove("bigPassword");
      } else {
        passwordInput.type = "password";
        self.tglpass = 'eye';
        passwordInput.classList.add("bigPassword");
      }
    });

    passwordInput.addEventListener("input", function() {
      if (passwordInput.value.trim() !== "") {
        passwordInput.classList.add("bigPassword");
      } else {
        passwordInput.classList.remove("bigPassword");
      }
    });
  },
  updated() {
    if (this.$route.params.msg !== undefined) {
      this.msg = this.$route.params.msg
    }
  }
}
</script>

<style scoped>

.card:hover {
  overflow: hidden;
  transform: none;
}

body {
  background: #5fd2e7;
}

.title-small {
  font-size: 20px;
  font-weight: bold;
}

.login-form {
  max-width: 450px;
  padding: 40px 20px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  margin: auto;
  position: relative;
  left: 0;
  right: 0;

}

.form-group {
  position: relative;
  margin-bottom: 15px;
}

.form-control {
  width: 100%;
  height: 40px;
  border: none;
  font-size: 15px !important;
  padding: 5px 7px 5px 15px;
  background: #fff;
  color: #666;
  border: 2px solid #ddd;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

.form-control:focus, .form-control:focus + .fa {
  border-color: #10CE88;
  color: #10CE88;
}

.form-group .fa {
  position: absolute;
  right: 15px;
  top: 11px;
  color: #999;
}

.log-status.wrong-entry {
  -moz-animation: wrong-log 0.3s;
  -webkit-animation: wrong-log 0.3s;
  animation: wrong-log 0.3s;
}

.log-status.wrong-entry .form-control, .wrong-entry .form-control + .fa {
  border-color: #ed1c24;
  color: #ed1c24;
}

.btn {
  background: #FFF;
  display: inline-block;
  width: 100%;
  font-weight: bold !important;
  height: 40px;
  border: none;
  border: 1px solid #ced4da !important;
}

.btn:hover {
  background-color: #ced4da !important;
}

.login-links {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.link {
  text-decoration: none;
  color: #000;
  float: right;
  font-size: 15px;
  margin-bottom: 15px;
}


/*password eye*/
.password-container {
  position: relative;
}

#password {
  padding-right: 30px; /* Space for the icon */
}

.bigPassword {
  font-size:35px !important;
}

.toggle-password {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

</style>
