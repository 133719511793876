<template>
  <div v-show="showhide" class="col-lg-12 mt-4 mb-4">
    <div class="row p-3">
      <div class="buy-again bg-light">
        <h4><b>Buy Again</b></h4>
        <button type="button" class="btn-close invisible" aria-label="Close"></button>
        <div class="MultiCarousel p-2" data-items="1,2,3,4" data-slide="1" id="MultiCarousel" data-interval="1000">
          <div class="MultiCarousel-inner">

            <div v-show="showbuy" class="item" v-for="item in recentPurchase" :key="item.Code">
              <div class="card card-products card-small shadow-md">
                <div class="card-img">
                  <img :src="imageUrl(item)" @error="imageUrlAlt" class="card-img-top" alt="..."
                       style="width:182px; height:42px;">
                </div>
                <div class="card-body">
                  <span class="desc-text text-uppercase d-block">#:{{ item.Code }}</span>
                  <span class="d-block subdued lh-sm mb-2 descBreak">{{item.description}}</span>
                  <a href="#" class="btn btn-dark rounded-0" @click.prevent="goToShop(item.Code)">+ Add to Cart</a>
                </div>

              </div>
            </div>

          </div>
          <button class="btn btn-primary leftLst"><i class="bi bi-chevron-left"></i></button>
          <button class="btn btn-primary rightLst"><i class="bi bi-chevron-right"></i></button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from 'jquery'
import {defineComponent} from 'vue'
import {mapGetters} from "vuex";

export default defineComponent({
  name: "RecentPurchase",
  data() {
    return {
      showhide:false,
      showbuy:false,
      loader: "dots",
      defaultImg: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=',
    };
  },
  computed: {
    ...mapGetters({
      recentPurchase: "Accounts/getRecentPurchase",
    }),
  },
  methods: {
    goToShop(Code) {
      window.location.href = '/#/Shop/' + Code + '/Y';
    },
    imageUrl(item) {
      let loc = process.env.VUE_APP_CDN + "/frames/" + item.Code + "/" + item.Code + "-small.jpg";
      if (item.FrameType === 'MATS') {
        loc = process.env.VUE_APP_CDN + "/mat/" + item.ManufCode + "/" + item.ManufCode + "-small.jpg";
      }
      return loc;
    },
    imageUrlAlt(event) {
      event.target.src = this.defaultImg;
    },
  },
  mounted() {
    this.$store.dispatch('Accounts/recentPurchase',{}).then(async rez => {
      if(rez.length > 0) {
        this.showhide = true
        this.showbuy = true
        await this.$store.dispatch('Accounts/actionSetRecentPurchase', rez)

        //this.loader.hide()
        var itemsMainDiv = ('.MultiCarousel');
        var itemsDiv = ('.MultiCarousel-inner');
        var itemWidth = "";

        $('.leftLst, .rightLst').click(function () {
          var condition = $(this).hasClass("leftLst");
          if (condition)
            click(0, this);
          else
            click(1, this)
        });

        ResCarouselSize();


        $(window).resize(function () {
          ResCarouselSize();
        });
      }
        //this function define the size of the items
        function ResCarouselSize() {
          var incno = 0;
          var dataItems = ("data-items");
          var itemClass = ('.item');
          var id = 0;
          var btnParentSb = '';
          var itemsSplit = '';
          var sampwidth = $(itemsMainDiv).width();
          var bodyWidth = $('body').width();
          $(itemsDiv).each(function () {
            id = id + 1;
            var itemNumbers = $(this).find(itemClass).length;
            btnParentSb = $(this).parent().attr(dataItems);
            itemsSplit = btnParentSb.split(',');
            $(this).parent().attr("id", "MultiCarousel" + id);


            if (bodyWidth >= 1200) {
              incno = itemsSplit[3];
              itemWidth = sampwidth / incno;
            } else if (bodyWidth >= 992) {
              incno = itemsSplit[2];
              itemWidth = sampwidth / incno;
            } else if (bodyWidth >= 768) {
              incno = itemsSplit[1];
              itemWidth = sampwidth / incno;
            } else {
              incno = itemsSplit[0];
              itemWidth = sampwidth / incno;
            }
            $(this).css({'transform': 'translateX(0px)', 'width': itemWidth * itemNumbers});
            $(this).find(itemClass).each(function () {
              $(this).outerWidth(itemWidth);
            });

            $(".leftLst").addClass("over");
            $(".rightLst").removeClass("over");

          });
        }


        //this function used to move the items
        function ResCarousel(e, el, s) {
          var leftBtn = ('.leftLst');
          var rightBtn = ('.rightLst');
          var translateXval = '';
          var divStyle = $(el + ' ' + itemsDiv).css('transform');
          var values = divStyle.match(/-?[\d.]+/g);
          var xds = Math.abs(values[4]);
          if (e == 0) {
            translateXval = parseInt(xds) - parseInt(itemWidth * s);
            $(el + ' ' + rightBtn).removeClass("over");

            if (translateXval <= itemWidth / 2) {
              translateXval = 0;
              $(el + ' ' + leftBtn).addClass("over");
            }
          } else if (e == 1) {
            var itemsCondition = $(el).find(itemsDiv).width() - $(el).width();
            translateXval = parseInt(xds) + parseInt(itemWidth * s);
            $(el + ' ' + leftBtn).removeClass("over");

            if (translateXval >= itemsCondition - itemWidth / 2) {
              translateXval = itemsCondition;
              $(el + ' ' + rightBtn).addClass("over");
            }
          }
          $(el + ' ' + itemsDiv).css('transform', 'translateX(' + -translateXval + 'px)');
        }

        //It is used to get some elements from btn
        function click(ell, ee) {
          var Parent = "#" + $(ee).parent().attr("id");
          var slide = $(Parent).attr("data-slide");
          ResCarousel(ell, Parent, slide);
        }



    })
  }
})
</script>


<style scoped>

.descBreak {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.buy-again {
  width: 100%;
  min-height: 280px;
  padding: 10px 4%;
  border: 1px solid #8C9196;
  position: relative;
}

.MultiCarousel {
  float: left;
  overflow: hidden;
  width: 100%;
  position: relative;
}

.MultiCarousel .MultiCarousel-inner {
  transition: 1s ease all;
  float: left;
}

.MultiCarousel .MultiCarousel-inner .item {
  float: left;
}

.MultiCarousel .MultiCarousel-inner .item > div {
  margin: 10px;
}

.MultiCarousel .leftLst, .MultiCarousel .rightLst {
  position: absolute;
  border-radius: 0%;
  top: calc(50% - 20px);
}

.MultiCarousel .leftLst {
  left: 0;
}

.MultiCarousel .rightLst {
  right: 0;
}

.MultiCarousel .leftLst.over, .MultiCarousel .rightLst.over {
  pointer-events: none;
  background: #ccc;
}

.buy-again .btn-close {
  position: absolute;
  right: 22px;
  top: 22px;
}

</style>