import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import VueGoogleMaps from '@fawmi/vue-google-maps'
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Maska from 'maska'
import moment from 'moment'
import VueGtag from "vue-gtag"
import './assets/css/main.css'
//import VueGtag from "vue-gtag";
//import '@/assets/fontawesome/css/all.css';
//import '@/assets/main.css';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import InactivityLogoutPlugin from './plugins/inactivityLogoutPlugin';
import cartCheckPlugin from "@/plugins/cartCheckPlugin";

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
//import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { fas } from "@fortawesome/free-solid-svg-icons";
/* add icons to the library */
library.add(fas)


window.$ = window.jQuery = require("jquery");

const app = createApp(App).mixin({
        methods: {
            formatCurrency(value) {
                let val = (value/1).toFixed(2).replace('.', '.')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
        },
    })
app.component('font-awesome-icon', FontAwesomeIcon)
app.config.globalProperties.$moment = moment

app.use(VueGtag, {
    config: { id: "G-89N5GZZR0H" }
});

app.use(InactivityLogoutPlugin, {
    timeoutDuration: 12 * 60 * 60 * 1000, // 12 hours in milliseconds
});
app.use(cartCheckPlugin, {
    timeoutDuration: 1 * 60 * 60 * 1000, // 1 hour in milliseconds
});
app.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GMAPS,
    },
}).use(Toast).use(store).use(Maska).use(router).use(VueLoading).mount('#app')
